import React from "react";
import moment from "moment";
function getNombreMes(fecha) {
    moment.locale('es');
    const dataFecha = moment(fecha).format('DD-MM-YYYY').toUpperCase();
    return dataFecha;
}

const TablesRecordsItemsCommissionExecutiveProcess = (props) => {
  const { idx,Success, fecha, periodo, yy} = props.data;
  if ( Success ) {
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='center'>{getNombreMes(fecha)}</td>
          <td align='right'>{periodo}</td>
          <td align='center'>{yy}</td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }
};

export default TablesRecordsItemsCommissionExecutiveProcess;
