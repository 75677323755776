import { useState, useEffect } from 'react';
import getExtensionistsContractPeriod from '../helpers/getExtensionistsContractPeriod'

const useGetExtensionistsContractPeriod = (Ffin, loadingAssigned, loadingDeallocate) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getExtensionistsContractPeriod(Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[Ffin, loadingAssigned, loadingDeallocate])

    return state

}

export default useGetExtensionistsContractPeriod