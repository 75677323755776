import React from 'react'
import Header from '../layout/header';
import Asidebar from '../layout/asidebar'

const Errorpage=()=>{
  const menu = [
    {   title:'Inicio',
        link:'/',
        icon:'fas fa-home icon'
    },
    {   title:'Comisiones',
        link:'/commissions',
        icon:'fas fa-award icon'
    }
  ]
    
    return    (
      <>
      <Header/>         
      <div className="columns is-variable is-0">
      <Asidebar menu={menu}/>
        <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
          <div className="p-1">
              
            <div className="columns is-variable is-desktop">
              <div className="column">
                <h1 className="title">
                  404
                </h1>
              </div>
            </div>
            <div className="columns  is-variable is-desktop">
              <div className="column">
                      <h1>Error! 404 Pagina no existe!</h1>
              </div>
            </div>
              
          </div>
        </div> 
      </div>
  </>
    )

}

export default Errorpage