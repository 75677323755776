import { useState, useEffect } from 'react';
import getEjecutivosDetails from '../helpers/getEjecutivoInversionesNvas'

const useGetExecutiveInvNuevas = (IDEjecutivo, Ffin) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getEjecutivosDetails(IDEjecutivo, Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[IDEjecutivo, Ffin])

    return state

}

export default useGetExecutiveInvNuevas