
const getEjecutivosDetails=async(IDEjecutivo, Ffin, tipoPersona)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    if (IDEjecutivo === ""){
        IDEjecutivo=0;
    }
    if (Ffin === ""){
        Ffin=0;
    }
    const url= tipoPersona === "EJE" ? `${host}api/comisiones/saldosejec/${IDEjecutivo}/${Ffin}` : `${host}api/comisiones/saldosext/${IDEjecutivo}/${Ffin}`

  const token=localStorage.getItem('token')
  const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
        const { result, Success, Message, Error } = await resp.json();

        let idx = 0
        let ejecutivoDetails = []
        if(result !== undefined){
            ejecutivoDetails = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        sProducto: elemen.Producto,
                        idEjecutivo:IDEjecutivo,
                        ffinal:Ffin,
                        product: elemen.sProducto,
                        saldoIni: elemen.SaldoIni,
                        saldoFin: elemen.SaldoFin,
                        dif: elemen.Diferencia,
                        meta: elemen.Meta,
                        saldoVig: elemen.SaldoVigente,
                        saldoVen: elemen.SaldoVencido,
                        pCarteraV: elemen.PCartVenc,
                        comision: elemen.Comision,
                        tipoPersona,
                        Success,
                        Message
                    }
                })
        } else {
            ejecutivoDetails = [{idx:idx+=1, Success, product: Error}]
        }
        return ejecutivoDetails

}

export default getEjecutivosDetails
