const getExecutiveProductsNewsDetails=async(IDMOV)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url=`${host}api/comisiones/productosnuevosdetalle/${IDMOV}`;

    const token=localStorage.getItem('token')
    const resp = await fetch(url, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
        });
    const { result, Error } = await resp.json();
    let idx = 0;
    let ejecutivoProductsNewsDetails = [];
    if(result !== undefined){
        ejecutivoProductsNewsDetails = result.map(elemen => {
                return {
                    idx: idx+=1,
                    folioinversion: elemen.FolioInversion,
                    cliente: elemen.Cliente,
                    tipocliente: elemen.TipoCliente,
                    ejecutivo: elemen.Ejecutivo,
                    producto: elemen.Producto,
                    importe: elemen.Importe,
                    plazo: elemen.Plazo,
                    tipoinstruccion: elemen.sTipoInstruccion,
                    foliocontrato: elemen.sFolioContrato
                }
            })
    } else {
        ejecutivoProductsNewsDetails = [{idx:idx+=1 , message: Error}]
    }
    return ejecutivoProductsNewsDetails;
}

export default getExecutiveProductsNewsDetails;
