import React, { useState, useContext } from 'react'
import Header from '../layout/header'
import TableTitlesItems from '../components/TablesTitlesItems';
import Asidebar from '../layout/asidebar'
import useGetExtensionistsAssignedContracts from '../hooks/useGetExtensionistsAssignedContracts';
import { UserContext } from "../helpers/auth";
import useGetExtensionistsContractPeriod from '../hooks/useGetExtensionistsContractPeriod';
import TablesRecordsItemsContractsPeriod from '../components/TablesRecordsItemsContractsPeriod';
import assignedContractExt from '../helpers/assignedContractExt';
import deallocateConstractExt from '../helpers/deallocateConstractExt';
import swal from 'sweetalert';
const menu = [
    {   title:'Extensionistas',
        link:'/commissions-extensionists',
        icon:'fas fa-pen-fancy icon'
    },
    {   title:'Detalles',
        link:'/commissions-extensionists-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Asig. Operaciones',
        link:'/commissions-extensionists-contracts-assigned',
        icon:'fas fa-folder-plus icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-extensionists-process',
        icon:'fas fa-cog icon'
    }
]
const dataAsigContratos=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Cliente',
    },
    {
        Idx:2,
        title:'Folio',
    },
    {
        Idx:3,
        title:'Autorizado',
    },
    {
        Idx:4,
        title:'Fecha Inicio',
    },
    {
        Idx:5,
        title:'Asignado',
    },
    {
        Idx:6,
        title:'Renovación',
    },
    {
        Idx:7,
        title:'Acciones',
    }
]

const CommissionsExtensionistsContractsAssigned=()=>{
    const {userAuth}=useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 ? false : true
    // const lOperacionesLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true: false
    const [loadingAssigned, setLoadingAssigned] = useState({lLoadingData: false,IDMov:0});
    const [loadingDeallocate, setLoadingDeallocate] = useState({lLoadingData: false,IDMov:0});

    const [FfinInput, setFfinInput] = useState("");
    const [IDEjecutivoInput, setIDEjecutivoInput] = useState("");
    const [Ffin, setFfin] = useState("");
    const [FEjecutivos, setFEjecutivos] = useState("");
    
    const { data: extensionistas, loading } = useGetExtensionistsAssignedContracts(FEjecutivos);

    const handleInputValue = () => {
            setFfin(FfinInput)
    }

    const handleAssignedMov = (idMov, idPersonalAsignado) => {
        setLoadingAssigned({lLoadingData: true,IDMov:idMov});
        assignedContractExt({id_openueva: idMov, id_extensionista:idPersonalAsignado})
        .then( assi => {
            if (assi.Success) {
              setLoadingAssigned({lLoadingData: false,IDMov:idMov});
              swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
              setLoadingAssigned({lLoadingData: false,IDMov:idMov});
              assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
    }

    const handleDeallocateMov = (idMov) => {
        setLoadingDeallocate({lLoadingData: true,IDMov:idMov});
        deallocateConstractExt({id_openueva: idMov})
        .then( assi => {
            if (assi.Success) {
                setLoadingDeallocate({lLoadingData: false,IDMov:idMov});
              swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
                setLoadingDeallocate({lLoadingData: false,IDMov:idMov});
              assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
      }
    
    const {data: extensionistAssignedContract, loadingExtensionistAssignedContract} = useGetExtensionistsContractPeriod(Ffin, loadingAssigned,loadingDeallocate);
    
    return(
        <>
            <Header/>
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                Comisiones Ejecutivos
                                </h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">
                                    <div className="card">
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <div className="field-body">
                                                        <div className="field-label">
                                                                <label className="label has-text-info-light">Fecha</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="date"
                                                                        value={FfinInput}
                                                                            onChange={(e) => {
                                                                                setFfinInput(e.target.value);
                                                                                setFEjecutivos(e.target.value);
                                                                                if ( lEjecutivoLogin ) { setIDEjecutivoInput(IDPersonalUserLogin) }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="field-label">
                                                                <label className="label has-text-info-light">Asignar:</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <div className="select">
                                                                        <select
                                                                            value={ lEjecutivoLogin ? IDPersonalUserLogin: IDEjecutivoInput }
                                                                            onChange={(e) => {
                                                                                setIDEjecutivoInput(e.target.value);
                                                                            }}
                                                                            disabled = { lEjecutivoLogin }
                                                                        >
                                                                            <option disabled = {true} value=''>seleccione una opcion</option>
                                                                            {extensionistas.map((item, idx)=>{
                                                                                return <option value={item.id}>{item.PuestoAsignado}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    {loading ? <button className="button is-info is-loading" >Buscar</button> : <button className="button is-info" onClick={ handleInputValue }  >Buscar</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                        <div className="card-content">
                                            {/* componente dinamicos tablas */}
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <p>Operaciones</p>
                                                    </div>
                                                </div>
                                            </header>
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataAsigContratos.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingExtensionistAssignedContract && <p>No hay datos</p> }
                                                        {extensionistAssignedContract.map((item, idx)=>{
                                                        return <TablesRecordsItemsContractsPeriod key={idx}data={item}idasignar={IDEjecutivoInput}handleAssignedMov={handleAssignedMov}handleDeallocateMov={handleDeallocateMov}loadingAssigned={loadingAssigned}loadingDeallocate={loadingDeallocate}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    <div className="card">
                                    {/* TODO:Content */}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommissionsExtensionistsContractsAssigned
