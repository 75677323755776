import React from 'react'

import { NavLink } from 'react-router-dom';

const Asidebar=(props)=>{
  const menu = props.menu;
    return (
      <div>
        <div className="menu-container px-1 has-background-white">
          <div className="menu-wrapper py-1">
            <aside className="menu">
              <p className="menu-label has-text-lighter">General</p>
              <ul className="menu-list">
                <li>
                  <NavLink to="/home" className="has-text-black" activeClassName="is-active">
                    <i className="fas fa-tachometer-alt icon"></i>
                    Dashboard</NavLink>
                </li>
              </ul>
              {/* <MenuAdmin/> */}
              <p className="menu-label has-text-lighter">Panel de Usuario</p>
              <ul className="menu-list">
                {menu.map((item, idx)=>{
                          return (
                              <li key = {idx}>
                                <NavLink to={item.link} className="has-text-black" activeClassName="has-text-white is-active">
                                <i className={item.icon}></i>
                                {item.title}</NavLink>
                              </li>
                            )
                        })}
              </ul>
            </aside>
          </div>
        </div>
      </div>
    )
}

export default Asidebar