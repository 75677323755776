import React, { useState, useContext } from "react";
// import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../helpers/auth";

const Login = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const {setUser}=useContext(UserContext);
  const {userAuth}=useContext(UserContext);
  
  console.log('en login',userAuth)
  //llamada a backen;
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      usuario: userName,
      password: password,
    };
    const resp = await fetch(`${ process.env.REACT_APP_URL_API_AUTH }login`, {
    // const resp = await fetch("https://auth.sibware.net/login", {
    // const resp = await fetch("http://localHost:3600/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const jsonData = await resp.json();
    //console.log("Success:", jsonData.token);
    //llamada a backen
    if (jsonData.token) {
      
        setUser(jsonData.tokenData)
        localStorage.setItem('token',jsonData.token); 
        
        setLoggedIn(true);
    } else {
        setIsError(true);
        setMessage("Usuario o contraseña invalido");
     }
  };

  if (isLoggedIn) {
    
    return <Redirect to="/home" />;
  }
  if (isError) {
    console.log("error de usuarios o contraseña")
  }

  return (
    <section className="hero is-danger is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-3-widescreen">
              <form onSubmit={handleSubmit} className="box">
                <div className="field has-text-centered">
                  <img src="images/user.png" alt="" />
                </div>
                <div className="field">
                  <label className="label">Usuario</label>
                  <div className="control has-icons-left">
                    <input
                      type="text"
                      className="input"
                      placeholder="Nombre de usuario"
                      required
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setMessage("");
                      }}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-left">
                    <input
                      type="password"
                      className="input"
                      placeholder="*********"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setMessage("");
                      }}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                </div>
                    
                <div className="field">
                  <button className="button is-info">Acceder</button>
                </div>
                      <p className="is-error">{message}</p>
                      <p className={'has-text-grey-lighter has-text-weight-bold mr-0 px-0 is-size-10 is-family-monospace'}>v1.0.4</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
