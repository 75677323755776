const getBrokersCommissionPay = async (statusInput) => {
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`;
    const host = process.env.REACT_APP_URL_API_INTRANET;
    // let FConsulta = Ffin;
    // if (FConsulta === ""){
    //     FConsulta=0;
    // }
    const url = `${host}api/comisiones/comisionescalculobroker/${statusInput}`;

    const token = localStorage.getItem('token');

    const resp = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    });
    const { result, Success, Message, Error } = await resp.json();
    let idx = 0;
    let comisionCalcBks = [];
    if (result !== undefined) {
        comisionCalcBks = result.map((elemen) => {
            return {
                Success,
                idx: (idx += 1),
                idpay: elemen.ID,
                Personal: elemen.Personal,
                puestoPersonal: elemen.puestoPersonal,
                Cliente: elemen.Cliente,
                Folio: elemen.Folio,
                Producto: elemen.Producto,
                sEmpresa: elemen.sEmpresa,
                porcComisionCli: elemen.porcComisionCli,
                porcComisionPago: elemen.porcComisionPago,
                comPagadaCli: elemen.comPagadaCli,
                comPagadaPersonal: elemen.comPagadaPersonal,
                statusPago: elemen.statusPago,
                periodo: elemen.periodo,
                yy: elemen.yy,
                Message
            };
        });
    } else {
        comisionCalcBks = [{ Success, idx: (idx += 1), Error }];
    }
    return comisionCalcBks;
};

export default getBrokersCommissionPay;
