import React from "react";
import { financial } from '../functions/Generales'

const TablesRecordsItemsProductsNewsDetails = (props) => {
  const { idx,folioinversion,cliente, tipocliente, ejecutivo, producto, importe, plazo, tipoinstruccion, message, foliocontrato} = props.data;

  if ( message === undefined ){
    if ( producto === "IN" ){
      let sFolio = folioinversion.toString().padStart(6, '0');
      return (
        <tbody>
          <tr>
            <td>{idx}</td>
            <td align='right'>{sFolio}</td>
            <td align='left'>{cliente}</td>
            <td align='left'>{tipocliente}</td>
            <td align='left'>{ejecutivo}</td>
            <td align='left'>{producto}</td>
            <td align='right'>{financial(importe)}</td>
            <td align='right'>{plazo}</td>
            <td align='left'>{tipoinstruccion}</td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td>{idx}</td>
            <td align='right'>{foliocontrato}</td>
            <td align='left'>{cliente}</td>
            <td align='left'>{tipocliente}</td>
            <td align='left'>{ejecutivo}</td>
            <td align='left'>{producto}</td>
            <td align='right'>{financial(importe)}</td>
            <td align='left'>{tipoinstruccion}</td>
          </tr>
        </tbody>
      );
    }
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>  
          <td align='left'>{message}</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsProductsNewsDetails;