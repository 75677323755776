const getExtensionistsContractPeriod=async(fecha)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`;
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let FConsulta = fecha;
    if (FConsulta === ""){
        FConsulta=0;
    }
    const url=`${host}api/comisiones/asignaroperacionesext/${ FConsulta }`;
    const token=localStorage.getItem('token');

  const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
        const { result, Success, Message, Error } = await resp.json();

        let idx = 0
        let extensionistCtoPeriod = []
        if(result !== undefined){
            extensionistCtoPeriod = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        idMov: elemen.ID,
                        cliente:elemen.Cliente,
                        folio:elemen.Folio,
                        autorizado: elemen.Autorizado,
                        fInicio: elemen.FInicio,
                        asignado: elemen.Asignado,
                        renovacion: elemen.Renovacion,
                        ventaCruzada: elemen.VentaCruzada,
                        sEmpresa: elemen.sEmpresa,
                        Success,
                        Message
                    }
                })
        } else {
            extensionistCtoPeriod = [{idx:idx+=1, Success, product: Error}]
        }
        return extensionistCtoPeriod

}

export default getExtensionistsContractPeriod
