import React from "react";
import { Link } from 'react-router-dom'
import { financial } from '../functions/Generales'

const TablesRecordsItemsColNvas = (props) => {
  const { idx, Success, cliente, empresa ,porcentajecomision, comisioncli, comision, idmov, producto} = props.data;
  if ( Success ){
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'><Link to={`/commissions-inversiones-nuevas-details/${ idmov }/${ producto }`}>{cliente}</Link></td>
          <td align='left'>{empresa}</td>
          <td align='left'>{producto}</td>
          <td align='right'>{(comisioncli * 100).toFixed(2)}</td>
          <td align='right'>{(porcentajecomision * 100).toFixed(2)}</td>
          <td align='right'>{financial(comision)}</td>
        </tr>
      </tbody>
    );
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsColNvas;
