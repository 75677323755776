import React, { useState, useContext } from 'react'
import Header from '../layout/header'
import { financial, downloadFiles, filterEjectivosConsulta } from '../functions/Generales'
import { dates } from '../functions/Dates'
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItems from '../components/TablesRecordsItemsEjecutivoDetails';
import TablesRecordsItemsInvNvas from '../components/TablesRecordsItemsEjecutivoInversionesNvas';
import TablesRecordsItemsColNvas from '../components/TablesRecordsItemsEjecutivoColocacionNvas';
import Asidebar from '../layout/asidebar'
import useGetEjecutivos from '../hooks/useGetEjectutivos';
import useGetExecutiveDetails from '../hooks/useGetEjecutivoDetails';
import useGetExecutiveInvNuevas from '../hooks/useGetEjecutivoInversionNva';
import useGetExecutiveBonos from '../hooks/useGetExecutiveBonos';
import useGetExecutiveColocacionNuevos from '../hooks/useGetEjecutivoColocacionNva';
import useGetCommission from "../hooks/useGetCommission";
import useAddCommission from '../hooks/useAddCommission';
import TablesRecordsItemsExecutivesBonos from '../components/TablesRecordsItemsEjecutivoBonos';
import { UserContext } from "../helpers/auth";
import printReportExecutive from '../helpers/printReportExecutive';
import swal from 'sweetalert';
const menu = [
    {   title:'Ejecutivos',
        link:'/commissions-executives',
        icon:'fas fa-users icon'
    },
    {   title:'Detalles',
        link:'/commissions-executives-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-executives-process',
        icon:'fas fa-cog icon'
    }
]
const data=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Producto',
    },
    {
        Idx:2,
        title:'Saldo Inicial',
    },
    {
        Idx:3,
        title:'Saldo Final',
    },
    {
        Idx:4,
        title:'Dif.',
    },
    {
        Idx:5,
        title:'Meta',
    },
    {
        Idx:6,
        title:'Saldo Vencido',
    },
    {
        Idx:7,
        title:'% Car. Venc.',
    },
    {
        Idx:8,
        title:'Comision',
    }
]

const dataInvNvas=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Cliente',
    },
    {
        Idx:2,
        title:'Importe Inversión',
    },
    {
        Idx:3,
        title:'Plazo',
    },
    {
        Idx:4,
        title:'Empresa',
    },
    {
        Idx:5,
        title:'% Comision',
    },
    {
        Idx:6,
        title:'Comision',
    }
]
const dataColocacionNvas=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Cliente',
    },
    {
        Idx:2,
        title:'Empresa',
    },
    {
        Idx:3,
        title:'Producto',
    },
    {
        Idx:4,
        title:'% Comisión Cliente',
    },
    {
        Idx:5,
        title:'% Comision',
    },
    {
        Idx:6,
        title:'Comision',
    }
]

const dataBonos=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Concepto',
    },
    {
        Idx:2,
        title:'Importe Bono',
    }
]

const CommissionsExecutivesDetails=()=>{
    const {userAuth}=useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 ? false : true
    const lOperacionesLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true: false

    const [loadingPDFPay,setLoadingPDFPay] = useState(false);
    const [FfinInput, setFfinInput] = useState("");
    const [IDEjecutivoInput, setIDEjecutivoInput] = useState("");
    const [Ffin, setFfin] = useState("");
    const [FEjecutivos, setFEjecutivos] = useState("");
    const [IDEjecutivo, setIDEjecutivo] = useState("");
    const [AprovCommission, setAprovCommission] = useState({});

    const handleInputValue = () => {
            setFfin(FfinInput)
            setIDEjecutivo(IDEjecutivoInput)
    }
    const addCommission = (dataCommission) => {
        const partsDate = Ffin.split('-');
        setFfin(`${ partsDate[0] }-${ partsDate[1] }-15`)
        setAprovCommission(dataCommission);
    }

    useAddCommission(AprovCommission);

    const handlePrintReportExecutive = (id_ejecutivo, fecha) => {
        setLoadingPDFPay(true);
        printReportExecutive({info: { id_ejecutivo, fecha }, tipoPersona:'EJE'})
        .then( print => {
            if (print.Success) {
                downloadFiles( print );
                setLoadingPDFPay(false);
            } else if( !print.Success && print.Success !== undefined ) {
                setLoadingPDFPay(false);
                swal("Error",'No se pudo descargar el archivo');
            }
        } )
    }
    const fechaEjecutivo = dates(FEjecutivos)
    const { data: ejecutivosAll, loading } = useGetEjecutivos();
    const ejecutivos = filterEjectivosConsulta(ejecutivosAll,fechaEjecutivo.startDay,fechaEjecutivo.endDay,[1,2,3,10,13],'S');

    const {data: ejecutivosDetail, loadingData} = useGetExecutiveDetails(IDEjecutivo, Ffin, "EJE");
    const {data: ejecutivosInvNuevas, loadingDataInvNvas} = useGetExecutiveInvNuevas(IDEjecutivo, Ffin);
    const {data: ejecutivosBonos, loadingDataBonos} = useGetExecutiveBonos(IDEjecutivo, Ffin, "EJE");
    const {data: ejecutivosColocacionNuevasCR, loadingDataColNvasCR} = useGetExecutiveColocacionNuevos(IDEjecutivo, Ffin, "CR");
    const {data: ejecutivosColocacionNuevasAP, loadingDataColNvasAP} = useGetExecutiveColocacionNuevos(IDEjecutivo, Ffin, "AP");
    const {data: ejecutivosColocacionNuevasVP, loadingDataColNvasVP} = useGetExecutiveColocacionNuevos(IDEjecutivo, Ffin, "VP");

    const totalSaldos = ejecutivosDetail.reduce((acc, el)=>{
        return acc+= parseFloat(el.comision)
    },0)
    const totalInv = ejecutivosInvNuevas.reduce((acc, el)=>{
        return acc+= parseFloat(el.comision)
    },0)
    const totalCr = ejecutivosColocacionNuevasCR.reduce((acc, el)=>{
        return acc+= parseFloat(el.comision)
    },0)
    const totalAp = ejecutivosColocacionNuevasAP.reduce((acc, el)=>{
        return acc+= parseFloat(el.comision)
    },0)
    const totalVp = ejecutivosColocacionNuevasVP.reduce((acc, el)=>{
        return acc+= parseFloat(el.comision)
    },0)
    const totalSaldosBonos = ejecutivosBonos.reduce((acc, el)=>{
        return acc+= parseFloat(el.importebono)
    },0)
    
    const status = 1
    const tipo = 1
    const fechas = dates(Ffin)

    const { dataCommission } = useGetCommission(IDEjecutivo, Ffin, tipo)
    const statusCommission = dataCommission.success ? 'Aprobada' : 'Pendiente'

    const localCommission = {
        status,
        tipo,
        fecha: fechas ? fechas.hoyLarge : null ,
        yy: fechas ? parseInt(fechas.yy) : null,
        mes: fechas ? parseInt(fechas.month) : null,
        inist: fechas ? fechas.startDay : null ,
        finst: fechas ? fechas.endDay : null ,
        total_bono: parseFloat(totalSaldos ? totalSaldos : 0) + parseFloat(totalSaldosBonos ? totalSaldosBonos : 0),
        total_comi_inv: parseFloat(totalInv ? totalInv : 0),
        total_comi_apvp: parseFloat(totalAp ? totalAp : 0),
        total_comi_vp: parseFloat(totalVp ? totalVp : 0),
        total_comi_cred: parseFloat(totalCr ? totalCr : 0),
        id_ejecutivo: parseInt(IDEjecutivo ? IDEjecutivo : 0),
        PagoDiffSaldosIN: parseFloat(ejecutivosDetail.length > 1 ? ejecutivosDetail.find(el => el.sProducto === 'IN').comision : 0),
        PagoDiffSaldosAP: parseFloat(ejecutivosDetail.length > 1 ? ejecutivosDetail.find(el => el.sProducto === 'AP').comision : 0),
        PagoDiffSaldosCR: parseFloat(ejecutivosDetail.length > 1 ? ejecutivosDetail.find(el => el.sProducto === 'CR').comision : 0),
        PagoDiffSaldosPR: parseFloat(ejecutivosDetail.length > 1 ? ejecutivosDetail.find(el => el.sProducto === 'PR').comision : 0),
        PagoDiffSaldosVP: parseFloat(ejecutivosDetail.length > 1 ? ejecutivosDetail.find(el => el.sProducto === 'VP').comision : 0),
    }
    const dataApruebaCommission = { ...localCommission, total_apagar: parseFloat(localCommission.total_bono + localCommission.total_comi_apvp + localCommission.total_comi_cred + localCommission.total_comi_inv + localCommission.total_comi_vp)}
    const totalPagoAP = parseFloat(dataApruebaCommission.PagoDiffSaldosAP + dataApruebaCommission.total_comi_apvp);
    const totalPagoCR = parseFloat(dataApruebaCommission.PagoDiffSaldosCR + dataApruebaCommission.total_comi_cred);
    const totalPagoIN = parseFloat(dataApruebaCommission.PagoDiffSaldosIN + dataApruebaCommission.total_comi_inv);
    const totalPagoPR = parseFloat(dataApruebaCommission.PagoDiffSaldosPR);
    const totalPagoVP = parseFloat(dataApruebaCommission.PagoDiffSaldosVP + dataApruebaCommission.total_comi_vp);
    const totalPagoBonos = isNaN(totalSaldosBonos) ? 0 : parseFloat(totalSaldosBonos);
    return(
        <>
            <Header/>
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                Comisiones Ejecutivos
                                </h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">
                                    <div className="card">
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <div className="field-body">
                                                        <div className="field-label">
                                                                <label className="label has-text-info-light">Fecha</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="date"
                                                                        value={FfinInput}
                                                                            onChange={(e) => {
                                                                                setFfinInput(e.target.value);
                                                                                setFEjecutivos(e.target.value);
                                                                                if ( lEjecutivoLogin ) { setIDEjecutivoInput(IDPersonalUserLogin) }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="field-label">
                                                                <label className="label has-text-info-light">Ejecutivo</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <div className="select">
                                                                        <select
                                                                            value={ lEjecutivoLogin ? IDPersonalUserLogin: IDEjecutivoInput }
                                                                            onChange={(e) => {
                                                                                setIDEjecutivoInput(e.target.value);
                                                                            }}
                                                                            disabled = { lEjecutivoLogin }
                                                                        >
                                                                            <option disabled = {true} value=''>seleccione una opcion</option>
                                                                            {ejecutivos.map((item, idx)=>{
                                                                                return <option value={item.id}>{item.ejecutivo}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    {loading ? <button className="button is-info is-loading" >Buscar</button> : <button className="button is-info" onClick={ handleInputValue }  >Buscar</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                        <div className="card-content">
                                            {/* componente dinamicos tablas */}
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {data.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingData && <p>No hay datos</p> }
                                                        {ejecutivosDetail.map((item, idx)=>{
                                                        return <TablesRecordsItems key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Inversiones</p>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataInvNvas.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataInvNvas && <p>No hay datos</p> }
                                                        {ejecutivosInvNuevas.map((item, idx)=>{
                                                        return <TablesRecordsItemsInvNvas key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Colocación</p>
                                                </div>
                                            </div>
                                        </header>

                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataColocacionNvas.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataColNvasCR && <p>No hay datos</p> }
                                                        {ejecutivosColocacionNuevasCR.map((item, idx)=>{
                                                        return <TablesRecordsItemsColNvas key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataColocacionNvas.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataColNvasAP && <p>No hay datos</p> }
                                                        {ejecutivosColocacionNuevasAP.map((item, idx)=>{
                                                        return <TablesRecordsItemsColNvas key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataColocacionNvas.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataColNvasVP && <p>No hay datos</p> }
                                                        {ejecutivosColocacionNuevasVP.map((item, idx)=>{
                                                        return <TablesRecordsItemsColNvas key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>


                                    {/* TODO:Content */}
                                    </div>
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Bonos</p>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataBonos.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataBonos && <p>No hay datos</p> }
                                                        {ejecutivosBonos.map((item, idx)=>{
                                                        return <TablesRecordsItemsExecutivesBonos key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <footer className="card-footer">
                                            <p className="card-footer-item title is-5">
                                              <span>
                                                Estatus Comisión: { statusCommission }
                                              </span>
                                            </p>
                                            <div className="p-2">
                                                <tr>
                                                    <td>Total AP : </td>
                                                    <td align="right">{ financial(totalPagoAP)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total CR : </td>
                                                    <td align="right">{ financial(totalPagoCR)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total IN : </td>
                                                    <td align="right">{ financial(totalPagoIN)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total PR : </td>
                                                    <td align="right">{ financial(totalPagoPR)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total VP : </td>
                                                    <td align="right">{ financial(totalPagoVP)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Bonos : </td>
                                                    <td align="right">{ financial(totalPagoBonos)}</td>
                                                </tr>                                                
                                            </div>
                                            <p className="card-footer-item title is-5">
                                              <span>
                                                Total a pagar : { financial(dataApruebaCommission.total_bono + dataApruebaCommission.total_comi_apvp + dataApruebaCommission.total_comi_cred + dataApruebaCommission.total_comi_inv + dataApruebaCommission.total_comi_vp)}
                                              </span>
                                            </p>
                                            <div className="p-2">
                                                { !dataCommission.success && lOperacionesLogin ? <button className="button is-info" onClick={(e) => addCommission(dataApruebaCommission)} >Aprobar</button> : ''}
                                            </div>
                                            <div className="p-2">
                                            { loadingPDFPay ? <button className="button is-info is-loading" ></button> : <button className="button is-info fas fa-file-pdf" onClick={ (e) => handlePrintReportExecutive(IDEjecutivo, Ffin) } ></button> }
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommissionsExecutivesDetails
