import { useState, useEffect } from 'react';
import getEjecutivosBonos from '../helpers/getEjecutivosBonos'

const useGetExecutiveBonos = (IDEjecutivo, Ffin, tipoPersona) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getEjecutivosBonos(IDEjecutivo, Ffin, tipoPersona)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[IDEjecutivo, Ffin, tipoPersona])

    return state

}

export default useGetExecutiveBonos