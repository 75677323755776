// import logo from '../img/logo.svg';
import React,{useState} from 'react';
import '../css/App.css';
import Rutas from '../routes/routes';
import { UserContext } from '../helpers/auth';

const App=()=> {
  const[userAuth,setUser]=useState({})
  return (
    <div className="App">
      <>
      <UserContext.Provider value={{
        userAuth,
        setUser
      }}>
       <Rutas/>

      </UserContext.Provider>
      </>
    </div>
  );
}

export default App;
