import { useState, useEffect } from 'react';
import getBrokersContractPeriod from '../helpers/getBrokersContractPeriod'

const useGetBrokersContractPeriod = (Ffin, loadingAssigned, loadingDeallocate) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getBrokersContractPeriod(Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[Ffin, loadingAssigned, loadingDeallocate])

    return state

}

export default useGetBrokersContractPeriod