import React from 'react'
import { useParams } from "react-router-dom";
import Header from '../layout/header'
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItems from '../components/TablesRecordsItemsSaldosDetails';
import Asidebar from '../layout/asidebar'
import useGetSaldosDetails from '../hooks/useGetSaldosDetails';
const menu = [
    {   title:'Extensionistas',
        link:'/commissions-extensionists',
        icon:'fas fa-pen-fancy icon'
    },
    {   title:'Detalles',
        link:'/commissions-extensionists-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Asig. Operaciones',
        link:'/commissions-extensionists-contracts-assigned',
        icon:'fas fa-folder-plus icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-extensionists-process',
        icon:'fas fa-cog icon'
    }
]

const data=[
    {
        Idx:0,
        title:'#', 
    },
    {
        Idx:1,
        title:'Ejecutivo', 
    },
    {
        Idx:2,
        title:'Cliente', 
    },
    {
        Idx:3,
        title:'Tipo Cliente', 
    },
    {
        Idx:4,
        title:'Folio', 
    },
    {
        Idx:5,
        title:'Producto.', 
    },
    {
        Idx:6,
        title:'Importe.', 
    },
    {
        Idx:7,
        title:'Saldo Vigente', 
    },
    {
        Idx:8,
        title:'Saldo Vencido', 
    },
    {
        Idx:9,
        title:'Periodo', 
    },
    {
        Idx:10,
        title:'Año', 
    }
]

    

const CommissionsExecutivesDetails=()=>{
    const { TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO } = useParams();
    const {data: ejecutivosDetail, loading } = useGetSaldosDetails(TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO);
    return(
        <>
            <Header/>         
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                Comisiones Ejecutivos
                                </h1>
                            </div>
                        </div>
                        <div className="columns  is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">                
                                    <div className="card">
                                        <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    
                                                </div>
                                                
                                        </header>
                                        <div className="card-content">
                                        {/* componente dinamicos tablas */}
                                            <div className="media"> 
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                    {data.map((item, id)=>{
                                                        return <TableTitlesItems key={id}data={item}/>
                                                    })}                                           
                                                    </tr>
                                                </thead> 
                                                    {loading && <p>Cargando...</p> }
                                                    {ejecutivosDetail.map((item, idx)=>{
                                                    return <TablesRecordsItems key={idx}data={item}/>
                                                    })}
                                                </table>                       
                                            </div>
                                        </div>    
                                    
                                    {/* TODO:Content */}
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div> 
            </div>
        </>

    )
}


export default CommissionsExecutivesDetails