const getBrokersContractPeriod = async (fecha) => {
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`;
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let FConsulta = fecha;
    if (FConsulta === '') {
        FConsulta = 0;
    }
    const url = `${host}api/comisiones/asignaroperacionesbroker/${FConsulta}`;
    const token = localStorage.getItem('token');

    const resp = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    });
    const { result, Success, Message, Error } = await resp.json();

    let idx = 0;
    let brokersCtoPeriod = [];
    if (result !== undefined) {
        brokersCtoPeriod = result.map((elemen) => {
            return {
                idx: (idx += 1),
                idMov: elemen.ID,
                cliente: elemen.Cliente,
                folio: elemen.Folio,
                sobretasa: elemen.SobreTasa,
                plazo: elemen.Plazo,
                autorizado: elemen.Autorizado,
                fInicio: elemen.FInicio,
                tipooperacion: elemen.TipoOperacion,
                asignado: elemen.Asignado,
                renovacion: elemen.Renovacion,
                ventaCruzada: elemen.VentaCruzada,
                sEmpresa: elemen.sEmpresa,
                Success,
                Message
            };
        });
    } else {
        brokersCtoPeriod = [{ idx: (idx += 1), Success, product: Error }];
    }
    return brokersCtoPeriod;
};

export default getBrokersContractPeriod;
