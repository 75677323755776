const getExtensionistsCtosAssigned=async(Ffin)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`;
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let FConsulta = Ffin;
    let TipoPersonal = 'EXT';
    if (FConsulta === ""){
        FConsulta=0;
    }
    const url=`${host}api/comisiones/contratosasignadosext/${ FConsulta }/${ TipoPersonal }`;

    const token=localStorage.getItem('token')

    const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
        const { result, Success, Message, Error } = await resp.json();
        let idx = 0;
        let contratosAsignadoExt = [];
        if(result !== undefined){
            contratosAsignadoExt = result.map(elemen => {
                    return {
                        Success,
                        idx: idx+=1,
                        idasig: elemen.ID,
                        idContrato: elemen.IDContrato,
                        idPersonal: elemen.IDPersonal,
                        idCliente: elemen.IDCliente,
                        cliente: elemen.Cliente,
                        folio: elemen.Folio,
                        producto: elemen.Producto,
                        monto: elemen.Monto,
                        porcComision: elemen.porcComision,
                        comPagada: elemen.comPagada,
                        asignacion: elemen.Asignacion,
                        Message
                    }
                });
        } else {
            contratosAsignadoExt = [{Success, idx:idx+=1 , Error}];
        }
        return contratosAsignadoExt;
}

export default getExtensionistsCtosAssigned;
