const getSaldosDetails=async(TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url=TIPOEJECUTIVO === "EJE" ? `${host}api/comisiones/saldosdetalleejec/${EJECUTIVO}/${DATE}/${TIPO}/${PRODUCTO}` :  `${host}api/comisiones/saldosdetalleext/${EJECUTIVO}/${DATE}/${TIPO}/${PRODUCTO}`
    
    const token=localStorage.getItem('token')
    const resp = await fetch(url, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
        });
    const { result, Error } = await resp.json();
    let idx = 0
    let ejecutivoDetails = []
    if(result !== undefined){
        ejecutivoDetails = result.map(elemen => {
                return {
                    idx: idx+=1,
                    cliente: elemen.Cliente,
                    tipocliente: elemen.TipoCliente,
                    ejecutivo: elemen.Ejecutivo,
                    folio: elemen.Folio,
                    producto: elemen.Producto,
                    importe: elemen.Importe,
                    saldovig: elemen.SaldoVigente,
                    saldoVen: elemen.SaldoVencido,
                    periodo: elemen.Periodo,
                    yy: elemen.YY
                }
            })
    } else {
        ejecutivoDetails = [{idx:idx+=1 , product: Error}]
    }

    return ejecutivoDetails

}

export default getSaldosDetails
