import { useState, useEffect } from 'react';
import getExtensionistsCommissionPay from '../helpers/getExtensionistsCommissionPay'

const useGetExtensionistsCommissionPay = (Ffin, loadingAuthorization, loadingDisavowal) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getExtensionistsCommissionPay(Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[Ffin, loadingAuthorization, loadingDisavowal])

    return state

}

export default useGetExtensionistsCommissionPay