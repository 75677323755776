import React, { useContext } from 'react';
import { financial } from '../functions/Generales';
import { UserContext } from '../helpers/auth';
import { formatoFecha } from '../functions/Dates';

const TablesRecordsItemsCommissionBrokers = (props) => {
    const { userAuth } = useContext(UserContext);

    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    // const IDPersonalUserLogin = userAuth.idPersonal;
    const lSolicitarPago = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true : false;

    const { idx, fecha, mes, yy, total_apagar, status, Ejecutivo, id_ejecutivo, id_comision } = props.data;
    // const lSolicitarEjecutivoLogueado = parseFloat(IDPersonalUserLogin) === parseFloat(id_ejecutivo) ? true : false;

    if (props.Success) {
        return (
            <tbody>
                <tr>
                    <td>{idx}</td>
                    <td>{formatoFecha(fecha)}</td>
                    <td>{Ejecutivo}</td>
                    <td align="center">{mes}</td>
                    <td align="center">{yy}</td>
                    <td align="right">{financial(total_apagar)}</td>
                    <td>{status === 2 ? 'Confirmada' : status === 3 ? 'Pago Solicitado' : ''}</td>
                    <td align="center">
                        <div>
                            {
                                lSolicitarPago && status === 2 ? (
                                    <button
                                        className="button is-info fas fa-money-check-alt"
                                        onClick={(e) =>
                                            props.handleSendPayBks(id_comision, 3, id_ejecutivo, total_apagar, 'BKS')
                                        }
                                    ></button>
                                ) : status === 3 ? (
                                    <button className="button is-info fas fa-clipboard-check"></button>
                                ) : (
                                    ''
                                )
                                // (lSolicitarPago && status === 2) || (lSolicitarEjecutivoLogueado && status === 2) ? <button className="button is-info fas fa-money-check-alt" onClick={ (e) => props.handleSendPayExt(id_comision, 3, id_ejecutivo, total_apagar, 'EXT' ) } ></button> : status === 3 ? <button className="button is-info fas fa-clipboard-check"></button> : ''
                            }
                        </div>
                    </td>
                    <td align="center">
                        {/* { props.loadingPDFPay ? <button className="button is-info is-loading" ></button> : <button className="button is-info fas fa-file-pdf" onClick={ (e) => props.handlePrintReportBroker(21,'2022-05-31') } ></button> } */}
                        {props.loadingPDFPay.lLoadingFile && props.loadingPDFPay.IDComision === id_comision ? (
                            <button className="button is-info is-loading"></button>
                        ) : (
                            <button
                                className="button is-info fas fa-file-pdf"
                                onClick={(e) => props.handlePrintReportBroker(id_comision)}
                            ></button>
                        )}
                    </td>
                </tr>
            </tbody>
        );
    } else {
        return (
            <tbody>
                <tr>
                    <td align="left">No existe información</td>
                </tr>
            </tbody>
        );
    }
};

export default TablesRecordsItemsCommissionBrokers;
