import { useState, useEffect } from 'react';
import getExecutiveProductNewDetails from '../helpers/getEjecutivoProductsNewsDetails'
const useGetExecutiveProductNewDetails = (IDMOV) => {

    const [state, setState] = useState({
        data: [],
        loading: true
    })

    useEffect( ()=>{
        getExecutiveProductNewDetails(IDMOV)
            .then( saldos => {
                setState({
                    data: saldos,
                    loading: false
                })
            } )
      },[IDMOV])

    return state

}

export default useGetExecutiveProductNewDetails