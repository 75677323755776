import React, { useState, useContext } from 'react';
import Header from '../layout/header';
import { filterEjectivosConsulta } from '../functions/Generales';
import { dates } from '../functions/Dates';
import TableTitlesItems from '../components/TablesTitlesItems';
import Asidebar from '../layout/asidebar';
// import useGetBrokersAssignedContracts from '../hooks/useGetBrokersAssignedContracts';
import useGetEjecutivos from '../hooks/useGetEjectutivos';
import { UserContext } from '../helpers/auth';
import useGetBrokersContractPeriod from '../hooks/useGetBrokersContractPeriod';
import TablesRecordsItemsContractsPeriod from '../components/TablesRecordsItemsContractsPeriodBrokers';
import assignedContractBroker from '../helpers/assignedContractBroker';
import deallocateConstractBroker from '../helpers/deallocateConstractBroker';
import swal from 'sweetalert';
const menu = [
    { title: 'Brokers', link: '/commissions-brokers', icon: 'fas fa-briefcase icon' },
    { title: 'Detalles', link: '/commissions-brokers-details', icon: 'fas fa-info-circle icon' },
    { title: 'Asig. Operaciones', link: '/commissions-brokers-contracts-assigned', icon: 'fas fa-folder-plus icon' },
    { title: 'Calculo de Comisiones', link: '/commissions-brokers-process', icon: 'fas fa-cog icon' }
];
const dataAsigContratos = [
    {
        Idx: 0,
        title: '#'
    },
    {
        Idx: 1,
        title: 'Cliente'
    },
    {
        Idx: 2,
        title: 'Folio'
    },
    {
        Idx: 3,
        title: 'Plazo'
    },
    {
        Idx: 4,
        title: 'Sobre Tasa'
    },
    {
        Idx: 5,
        title: 'Autorizado'
    },
    {
        Idx: 6,
        title: 'Fecha Inicio'
    },
    {
        Idx: 7,
        title: 'Tipo Ope.'
    },
    {
        Idx: 8,
        title: 'Asignado'
    },
    {
        Idx: 9,
        title: 'Acciones'
    }
];

const CommissionsBrokersContractsAssigned = () => {
    const { userAuth } = useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? false : true;
    // const lOperacionesLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true: false
    const [loadingAssigned, setLoadingAssigned] = useState({ lLoadingData: false, IDMov: 0 });
    const [loadingDeallocate, setLoadingDeallocate] = useState({ lLoadingData: false, IDMov: 0 });

    const [FfinInput, setFfinInput] = useState('');
    const [IDEjecutivoInput, setIDEjecutivoInput] = useState('');
    const [IDBrokerInput, setIDBrokerInput] = useState('');
    const [Ffin, setFfin] = useState('');
    const [FEjecutivos, setFEjecutivos] = useState('');

    // const { data: extensionistas, loading } = useGetBrokersAssignedContracts(FEjecutivos);

    const fechaEjecutivo = dates(FEjecutivos);
    console.log(fechaEjecutivo);
    const { data: ejecutivosAll, loading } = useGetEjecutivos();
    const brokers = filterEjectivosConsulta(ejecutivosAll, fechaEjecutivo.startDay, fechaEjecutivo.endDay, [11], 'N');
    const ejecutivos = filterEjectivosConsulta(
        ejecutivosAll,
        fechaEjecutivo.startDay,
        fechaEjecutivo.endDay,
        [15, 14, 13, 10],
        'N'
    );

    const handleInputValue = () => {
        setFfin(FfinInput);
    };

    const handleAssignedMov = (idMov, idBrokerAsignado, idPersonalAsignado) => {
        setLoadingAssigned({ lLoadingData: true, IDMov: idMov });
        assignedContractBroker({
            id_openueva: idMov,
            id_broker: idBrokerAsignado,
            id_personal_asignado: idPersonalAsignado
        }).then((assi) => {
            if (assi.Success) {
                setLoadingAssigned({ lLoadingData: false, IDMov: idMov });
                swal('Mensaje', assi.Message);
            } else if (!assi.Success && assi.Success !== undefined) {
                setLoadingAssigned({ lLoadingData: false, IDMov: idMov });
                assi.Error ? swal('Mensaje', assi.Error) : swal('Mensaje', assi.Message);
            }
        });
    };

    const handleDeallocateMov = (idMov) => {
        setLoadingDeallocate({ lLoadingData: true, IDMov: idMov });
        deallocateConstractBroker({ id_openueva: idMov }).then((assi) => {
            if (assi.Success) {
                setLoadingDeallocate({ lLoadingData: false, IDMov: idMov });
                swal('Mensaje', assi.Message);
            } else if (!assi.Success && assi.Success !== undefined) {
                setLoadingDeallocate({ lLoadingData: false, IDMov: idMov });
                assi.Error ? swal('Mensaje', assi.Error) : swal('Mensaje', assi.Message);
            }
        });
    };

    const { data: brokerAssignedContract, loadingBrokerAssignedContract } = useGetBrokersContractPeriod(
        Ffin,
        loadingAssigned,
        loadingDeallocate
    );

    return (
        <>
            <Header />
            <div className="columns is-variable is-0">
                <Asidebar menu={menu} />
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">Comisiones Brokers</h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className="column is-full">
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <div className="field-body">
                                                        <div className="field-label">
                                                            <label className="label has-text-info-light">Fecha</label>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <input
                                                                    className="input"
                                                                    type="date"
                                                                    value={FfinInput}
                                                                    onChange={(e) => {
                                                                        setFfinInput(e.target.value);
                                                                        setFEjecutivos(e.target.value);
                                                                        if (lEjecutivoLogin) {
                                                                            setIDEjecutivoInput(IDPersonalUserLogin);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                {/* componente dinamicos tablas */}
                                                <div className="field is-horizontal">
                                                    <div className="field-body">
                                                        {/* <div className="field-label">
                                                                <label className="label has-text-info-light">Broker:</label>
                                                            </div> */}
                                                        <div className="field">
                                                            <div className="control">
                                                                <div className="select">
                                                                    <select
                                                                        value={
                                                                            lEjecutivoLogin
                                                                                ? IDPersonalUserLogin
                                                                                : IDBrokerInput
                                                                        }
                                                                        onChange={(e) => {
                                                                            setIDBrokerInput(e.target.value);
                                                                        }}
                                                                        disabled={lEjecutivoLogin}
                                                                    >
                                                                        <option disabled={true} value="">
                                                                            Broker
                                                                        </option>
                                                                        {brokers.map((item, idx) => {
                                                                            return (
                                                                                <option value={item.id}>
                                                                                    {item.ejecutivo}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="field-label">
                                                                <label className="label has-text-info-light">Gerente:</label>
                                                            </div> */}
                                                        <div className="field">
                                                            <div className="control">
                                                                <div className="select">
                                                                    <select
                                                                        value={
                                                                            lEjecutivoLogin
                                                                                ? IDPersonalUserLogin
                                                                                : IDEjecutivoInput
                                                                        }
                                                                        onChange={(e) => {
                                                                            setIDEjecutivoInput(e.target.value);
                                                                        }}
                                                                        disabled={lEjecutivoLogin}
                                                                    >
                                                                        <option disabled={true} value="">
                                                                            Personal
                                                                        </option>
                                                                        {ejecutivos.map((item, idx) => {
                                                                            return (
                                                                                <option value={item.id}>
                                                                                    {item.ejecutivo}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                {loading ? (
                                                                    <button className="button is-info is-loading">
                                                                        Buscar
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="button is-info"
                                                                        onClick={handleInputValue}
                                                                    >
                                                                        Buscar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            {/* componente dinamicos tablas */}
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <p>Operaciones</p>
                                                    </div>
                                                </div>
                                            </header>
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataAsigContratos.map((item, id) => {
                                                                return <TableTitlesItems key={id} data={item} />;
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    {loadingBrokerAssignedContract && <p>No hay datos</p>}
                                                    {brokerAssignedContract.map((item, idx) => {
                                                        return (
                                                            <TablesRecordsItemsContractsPeriod
                                                                key={idx}
                                                                data={item}
                                                                idasignarbroker={IDBrokerInput}
                                                                idasignarpersonal={IDEjecutivoInput}
                                                                handleAssignedMov={handleAssignedMov}
                                                                handleDeallocateMov={handleDeallocateMov}
                                                                loadingAssigned={loadingAssigned}
                                                                loadingDeallocate={loadingDeallocate}
                                                            />
                                                        );
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card">{/* TODO:Content */}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommissionsBrokersContractsAssigned;
