import { useState, useEffect } from 'react';
import getExtensionists from '../helpers/getExtensionists'

const useGetEjecutivoExtensionists = (Ffin) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getExtensionists(Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[Ffin])

    return state

}

export default useGetEjecutivoExtensionists