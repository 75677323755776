import  { useState, useEffect } from 'react'
import apruebaCommission from '../helpers/apruebaCommission'
import swal from 'sweetalert';

const useAddCommission = (dataCommission) => {

    const [state, setState] = useState({
        data: [],
        loading: true
    })

    useEffect( ()=>{
        apruebaCommission(dataCommission)
            .then( aprobar => {
                setState({
                    data: aprobar,
                    loading: false
                })
                if (aprobar.Success) {
                    swal("Aprobada", aprobar.Message);
                } else if( !aprobar.Success && aprobar.Message !== undefined ) {
                    swal("Mensaje", aprobar.Message);
                }
            } )
      },[dataCommission])

    return state
}

export default useAddCommission