const authCommissionBrokerProcess = async (data) => {
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url = `${host}api/comisiones/proctotalizarpagosbrokers`;
    const token = localStorage.getItem('token');
    const resp = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        },
        body: JSON.stringify(data)
    });
    const { Success, Message, Error } = await resp.json();
    let idx = 0;
    let authCommissionProc;
    if (Success) {
        authCommissionProc = { Success, Message };
    } else {
        authCommissionProc = { idx: (idx += 1), Success, Message: Message, Error };
    }
    return authCommissionProc;
};

export default authCommissionBrokerProcess;
