import React, { useState, useContext } from 'react'
import Header from '../layout/header'
import { downloadFiles } from '../functions/Generales'
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItems from '../components/TablesRecordsItemsEjecutivoDetails';
import TablesRecordsItemsExtensionistsCtosAssigned from '../components/TablesRecordsItemsExtensionistsCtosAssigned';
import TablesRecordsItemsExtensionistsCommissionsPay from '../components/TablesRecordsItemsExtensionistsCommissionsPay';
import Asidebar from '../layout/asidebar'
import useGetExtensionists from '../hooks/useGetExtensionists';
import useGetExecutiveDetails from '../hooks/useGetEjecutivoDetails';
import useGetExtensionistsCtosAssigned from '../hooks/useGetExtensionistsCtosAssigned';
import useGetExecutiveBonos from '../hooks/useGetExecutiveBonos';
import useGetExtensionistsCommissionPay from '../hooks/useGetExtensionistsCommissionPay';
import TablesRecordsItemsExecutivesBonos from '../components/TablesRecordsItemsEjecutivoBonos';
import { UserContext } from "../helpers/auth";
import printReportExecutive from '../helpers/printReportExecutive';
import apruebaCommissionExt from '../helpers/apruebaCommissionExt';
import desapruebaCommissionExt from '../helpers/desapruebaCommissionExt';
import swal from 'sweetalert';
const menu = [
    {   title:'Extensionistas',
        link:'/commissions-extensionists',
        icon:'fas fa-pen-fancy icon'
    },
    {   title:'Detalles',
        link:'/commissions-extensionists-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Asig. Operaciones',
        link:'/commissions-extensionists-contracts-assigned',
        icon:'fas fa-folder-plus icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-extensionists-process',
        icon:'fas fa-cog icon'
    }
]

const data=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Producto',
    },
    {
        Idx:2,
        title:'Saldo Inicial',
    },
    {
        Idx:3,
        title:'Saldo Final',
    },
    {
        Idx:4,
        title:'Dif.',
    }
]

const dataComisionesPagar=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Nombre',
    },
    {
        Idx:2,
        title:'%P',
    },
    {
        Idx:3,
        title:'%C',
    },
    {
        Idx:4,
        title:'Arrendamientos',
    },
    {
        Idx:5,
        title:'Creditos',
    },
    {
        Idx:6,
        title:'Venta Plazo',
    },
    {
        Idx:7,
        title:'Inversiones',
    },
    {
        Idx:8,
        title:'Bonos',
    },
    {
        Idx:9,
        title:'Total',
    },
    {
        Idx:10,
        title:'Acciones',
    }
]

const dataContratosAsig=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Cliente',
    },
    {
        Idx:2,
        title:'Folio',
    },
    {
        Idx:3,
        title:'Producto',
    },
    {
        Idx:4,
        title:'Monto',
    },
    {
        Idx:5,
        title:'% Comision',
    },
    {
        Idx:6,
        title:'% Comision Pagada',
    },
    {
        Idx:7,
        title:'Asignado a:',
    }
]

const dataBonos=[
    {
        Idx:0,
        title:'#',
    },
    {
        Idx:1,
        title:'Concepto',
    },
    {
        Idx:2,
        title:'Importe Bono',
    }
]

const CommissionsExtensionistsDetails=()=>{
    const {userAuth}=useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 ? false : true
    // const lOperacionesLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true: false

    const [loadingPDFPay,setLoadingPDFPay] = useState(false);
    const [FfinInput, setFfinInput] = useState("");
    const [IDEjecutivoInput, setIDEjecutivoInput] = useState("");
    const [Ffin, setFfin] = useState("");
    const [FEjecutivos, setFEjecutivos] = useState("");
    const [IDEjecutivo, setIDEjecutivo] = useState("");
    const [loadingAuthorization, setLoadingAuthorization] = useState({lLoadingData: false,IDMov:0});
    const [loadingDisavowal, setLoadingDisavowal] = useState({lLoadingData: false,IDMov:0});
    
    const { data: extensionistas, loading } = useGetExtensionists(FEjecutivos);

    const handleAuthorizationMov = (idMov) => {
        setLoadingAuthorization({lLoadingData: true,IDMov:idMov});
        apruebaCommissionExt({id_aprobar: idMov})
        .then( assi => {
            if (assi.Success) {
                setLoadingAuthorization({lLoadingData: false,IDMov:idMov});
              swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
                setLoadingAuthorization({lLoadingData: false,IDMov:idMov});
              assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
    }

    const handleDisawovalMov = (idMov) => {
        setLoadingDisavowal({lLoadingData: true,IDMov:idMov});
        desapruebaCommissionExt({id_aprobar: idMov})
        .then( assi => {
            if (assi.Success) {
                setLoadingDisavowal({lLoadingData: false,IDMov:idMov});
              swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
                setLoadingDisavowal({lLoadingData: false,IDMov:idMov});
              assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
      }

    const handleInputValue = () => {
            setFfin(FfinInput)
            setIDEjecutivo(IDEjecutivoInput)
    }

    const handlePrintReportExtensionist = ( fecha) => {
        setLoadingPDFPay(true);
        printReportExecutive({info: { fecha }, tipoPersona:'EXT'})
        .then( print => {
            if (print.Success) {
                downloadFiles( print );
                setLoadingPDFPay(false);
            } else if( !print.Success && print.Success !== undefined ) {
                setLoadingPDFPay(false);
                swal("Error",'No se pudo descargar el archivo');
            }
        } )
    }
    
    const {data: extensionistsDetail, loadingData} = useGetExecutiveDetails(IDEjecutivo, Ffin, "EXT");
    const {data: extensionistsBonos, loadingDataBonos} = useGetExecutiveBonos(IDEjecutivo, Ffin, "EXT");

    const {data: extensionistsContratosAsig, loadingContratosAsig} = useGetExtensionistsCtosAssigned(Ffin);
    const {data: extensionistsComPagar, loadingComPagar} = useGetExtensionistsCommissionPay(Ffin, loadingAuthorization, loadingDisavowal);
    
    return(
        <>
            <Header/>
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                Comisiones Ejecutivos
                                </h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">
                                    <div className="card">
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <div className="field-body">
                                                        <div className="field-label">
                                                                <label className="label has-text-info-light">Fecha</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="date"
                                                                        value={FfinInput}
                                                                            onChange={(e) => {
                                                                                setFfinInput(e.target.value);
                                                                                setFEjecutivos(e.target.value);
                                                                                if ( lEjecutivoLogin ) { setIDEjecutivoInput(IDPersonalUserLogin) }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="field-label">
                                                                <label className="label has-text-info-light">Ejecutivo</label>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <div className="select">
                                                                        <select
                                                                            value={ lEjecutivoLogin ? IDPersonalUserLogin: IDEjecutivoInput }
                                                                            onChange={(e) => {
                                                                                setIDEjecutivoInput(e.target.value);
                                                                            }}
                                                                            disabled = { lEjecutivoLogin }
                                                                        >
                                                                            <option disabled = {true} value=''>seleccione una opcion</option>
                                                                            {extensionistas.map((item, idx)=>{
                                                                                return <option value={item.id}>{item.extensionista}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    {loading ? <button className="button is-info is-loading" >Buscar</button> : <button className="button is-info" onClick={ handleInputValue }  >Buscar</button>}
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <div className="control">
                                                                    { loadingPDFPay ? <button className="button is-info is-loading" ></button> : <button className="button is-info fas fa-file-pdf" onClick={ (e) => handlePrintReportExtensionist( Ffin ) } ></button> }
                                                                </div>                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                    <div className="card-content">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Comisiones a Pagar</p>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataComisionesPagar.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingComPagar && <p>No hay datos</p> }
                                                        {extensionistsComPagar.map((item, idx)=>{
                                                        return <TablesRecordsItemsExtensionistsCommissionsPay key={idx}data={item}handleAuthorizationMov={handleAuthorizationMov}handleDisawovalMov={handleDisawovalMov}loadingAuthorization={loadingAuthorization}loadingDisavowal={loadingDisavowal}/>
                                                        })}
                                                </table>
                                            </div>
                                            </div>
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Relación de Operaciones Asignadas</p>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataContratosAsig.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingContratosAsig && <p>No hay datos</p> }
                                                        {extensionistsContratosAsig.map((item, idx)=>{
                                                        return <TablesRecordsItemsExtensionistsCtosAssigned key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TODO:Content */}
                                    </div>
                                    <div className="card">
                                            <header className="card-header">
                                                    <div className="card-header-title has-background-danger has-text-light">
                                                        <div className="field is-horizontal">
                                                            <p>Saldos Cartera</p>
                                                        </div>
                                                    </div>
                                                </header>
                                                {/* componente dinamicos tablas */}
                                        <div className="card-content">
                                                <div className="media">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                {data.map((item, id)=>{
                                                                    return <TableTitlesItems key={id}data={item}/>
                                                                })}
                                                            </tr>
                                                        </thead>
                                                            {loadingData && <p>No hay datos</p> }
                                                            {extensionistsDetail.map((item, idx)=>{
                                                            return <TablesRecordsItems key={idx}data={item}/>
                                                            })}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <p>Bonos</p>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {dataBonos.map((item, id)=>{
                                                                return <TableTitlesItems key={id}data={item}/>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                        {loadingDataBonos && <p>No hay datos</p> }
                                                        {extensionistsBonos.map((item, idx)=>{
                                                        return <TablesRecordsItemsExecutivesBonos key={idx}data={item}/>
                                                        })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommissionsExtensionistsDetails
