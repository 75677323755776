import moment from 'moment'

async function DToday() {
    return moment().format('YYYY-MM-DD')
}

function dates (value) {
    const hoy = moment().format('YYYY-MM-DD');
    const hoyLarge = moment().format('YYYY-MM-DD HH:mm:ss');
    const date = value ? value : hoy
    const yy = moment(date).format('YYYY')
    const month =moment(date).format('MM')
    const startDay= moment(date).startOf("month").format('YYYY-MM-DD')
    const endDay= moment(date).endOf("month").format('YYYY-MM-DD')
    return {
        hoy,
        hoyLarge,
        yy,
        month,
        startDay,
        endDay,
    }
}

function formatoFecha(value) {
    return moment(value).format('DD/MM/YYYY');
}

export {
    dates,
    DToday,
    formatoFecha
};
