import React, { useState, useContext } from 'react';
import Header from '../layout/header';
// import { downloadFiles } from '../functions/Generales';
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItemsBrokersCommissionsPay from '../components/TablesRecordsItemsBrokersCommissionsPay';
import Asidebar from '../layout/asidebar';
import useGetBrokersCommissionPay from '../hooks/useGetBrokersCommissionPay';
import { UserContext } from '../helpers/auth';
// import printReportExecutive from '../helpers/printReportExecutive';
import authCommissionBrokerProcess from '../helpers/authCommissionBrokerProcess';
import authCommissionBroker from '../helpers/authCommissionBroker';
import desautorizarCommissionBroker from '../helpers/desautorizarComissionBroker';
import swal from 'sweetalert';
const menu = [
    { title: 'Brokers', link: '/commissions-brokers', icon: 'fas fa-briefcase icon' },
    { title: 'Detalles', link: '/commissions-brokers-details', icon: 'fas fa-info-circle icon' },
    { title: 'Asig. Operaciones', link: '/commissions-brokers-contracts-assigned', icon: 'fas fa-folder-plus icon' },
    { title: 'Calculo de Comisiones', link: '/commissions-brokers-process', icon: 'fas fa-cog icon' }
];

const dataComisionesPagar = [
    {
        Idx: 0,
        title: '#'
    },
    {
        Idx: 1,
        title: 'Personal'
    },
    {
        Idx: 2,
        title: 'Puesto'
    },
    {
        Idx: 3,
        title: 'Cliente'
    },
    {
        Idx: 4,
        title: 'Folio'
    },
    {
        Idx: 5,
        title: '%Comision'
    },
    {
        Idx: 6,
        title: 'Importe Pago'
    },
    {
        Idx: 7,
        title: 'Periodo'
    },
    {
        Idx: 8,
        title: 'Año'
    },
    {
        Idx: 9,
        title: 'Acciones'
    }
];

const CommissionsBrokersDetails = () => {
    const { userAuth } = useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    // const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? false : true;
    // const lOperacionesLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true: false

    // const [loadingPDFPay, setLoadingPDFPay] = useState(false);
    const [loadingProcessPay, setLoadingProcessPay] = useState(false);
    // const [FfinInput, setFfinInput] = useState('');
    // const [IDEjecutivoInput, setIDEjecutivoInput] = useState('');
    // const [Ffin, setFfin] = useState('');
    // const [FEjecutivos, setFEjecutivos] = useState('');
    // const [IDEjecutivo, setIDEjecutivo] = useState('');
    const [loadingAuthorization, setLoadingAuthorization] = useState({ lLoadingData: false, IDMov: 0 });
    const [loadingDisavowal, setLoadingDisavowal] = useState({ lLoadingData: false, IDMov: 0 });

    let nStatusValIni = 1;
    // if ( lEjecutivoLogin ) {
    //     nStatusValIni = 2;
    // } else if ( lMesaControlLogin ) {
    //     nStatusValIni = 1;
    // } else { nStatusValIni = 1 }
    const [statusInput, setstatusInput] = useState(nStatusValIni);

    const handleAuthorizationMov = (idMov) => {
        setLoadingAuthorization({ lLoadingData: true, IDMov: idMov });
        authCommissionBroker({ id_aprobar: idMov }).then((assi) => {
            if (assi.Success) {
                setLoadingAuthorization({ lLoadingData: false, IDMov: idMov });
                swal('Mensaje', assi.Message);
            } else if (!assi.Success && assi.Success !== undefined) {
                setLoadingAuthorization({ lLoadingData: false, IDMov: idMov });
                assi.Error ? swal('Mensaje', assi.Error) : swal('Mensaje', assi.Message);
            }
        });
    };

    const handleDisawovalMov = (idMov) => {
        setLoadingDisavowal({ lLoadingData: true, IDMov: idMov });
        desautorizarCommissionBroker({ id_aprobar: idMov }).then((assi) => {
            if (assi.Success) {
                setLoadingDisavowal({ lLoadingData: false, IDMov: idMov });
                swal('Mensaje', assi.Message);
            } else if (!assi.Success && assi.Success !== undefined) {
                setLoadingDisavowal({ lLoadingData: false, IDMov: idMov });
                assi.Error ? swal('Mensaje', assi.Error) : swal('Mensaje', assi.Message);
            }
        });
    };

    // const handleInputValue = () => {
    //     setFfin(FfinInput);
    //     setIDEjecutivo(IDEjecutivoInput);
    // };

    const handleProcessPaymentBroker = () => {
        setLoadingProcessPay(true);
        authCommissionBrokerProcess().then((auth) => {
            if (auth.Success) {
                setLoadingProcessPay(false);
                swal('Mensaje', auth.Message);
            } else if (!auth.Success && auth.Success !== undefined) {
                setLoadingProcessPay(false);
                auth.Error ? swal('Mensaje', auth.Error) : swal('Mensaje', auth.Message);
            }
        });
    };

    // const handlePrintReportExtensionist = (fecha) => {
    //     setLoadingPDFPay(true);
    //     printReportExecutive({ info: { fecha }, tipoPersona: 'EXT' }).then((print) => {
    //         if (print.Success) {
    //             downloadFiles(print);
    //             setLoadingPDFPay(false);
    //         } else if (!print.Success && print.Success !== undefined) {
    //             setLoadingPDFPay(false);
    //             swal('Error', 'No se pudo descargar el archivo');
    //         }
    //     });
    // };

    const { data: brokersComPagar, loadingComPagar } = useGetBrokersCommissionPay(
        statusInput,
        loadingAuthorization,
        loadingDisavowal,
        loadingProcessPay
    );

    return (
        <>
            <Header />
            <div className="columns is-variable is-0">
                <Asidebar menu={menu} />
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">Comisiones Ejecutivos</h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className="column is-full">
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <div className="field-body">
                                                        <div className="field-label">
                                                            <label className="label has-text-info-light">Estatus</label>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <div className="select">
                                                                    <select
                                                                        value={statusInput}
                                                                        onChange={(e) => {
                                                                            setstatusInput(e.target.value);
                                                                        }}
                                                                        disabled={lEjecutivoLogin ? true : false}
                                                                    >
                                                                        <option disabled={true} value="">
                                                                            seleccione una opcion
                                                                        </option>
                                                                        <option
                                                                            disabled={lEjecutivoLogin ? true : false}
                                                                            value={1}
                                                                        >
                                                                            Pendiente Autorizar
                                                                        </option>
                                                                        <option
                                                                            disabled={lEjecutivoLogin ? true : false}
                                                                            value={2}
                                                                        >
                                                                            Autorizada
                                                                        </option>
                                                                        <option
                                                                            disabled={lEjecutivoLogin ? true : false}
                                                                            value={3}
                                                                        >
                                                                            Enviados a pago
                                                                        </option>
                                                                        {/* <option
                                                                            disabled={lEjecutivoLogin ? true : false}
                                                                            value={null}
                                                                        >
                                                                            Todos
                                                                        </option> */}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="field">
                                                            <div className="control">
                                                                {loadingComPagar ? (
                                                                    <button className="button is-info is-loading">
                                                                        Buscar
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="button is-info"
                                                                        onClick={handleInputValue}
                                                                    >
                                                                        Buscar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div> */}
                                                        <div className="field">
                                                            <div className="control">
                                                                {loadingProcessPay ? (
                                                                    <button className="button is-info is-loading"></button>
                                                                ) : statusInput === '2' ? (
                                                                    <button
                                                                        className="button is-info fas fa-file-invoice-dollar"
                                                                        onClick={(e) => handleProcessPaymentBroker()}
                                                                    ></button>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">{/* TODO:Content */}</div>
                                        <div className="card">
                                            <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                    <div className="field is-horizontal">
                                                        <p>Comisiones a Pagar</p>
                                                    </div>
                                                </div>
                                            </header>
                                            <div className="card-content">
                                                <div className="media">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                {dataComisionesPagar.map((item, id) => {
                                                                    return <TableTitlesItems key={id} data={item} />;
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        {loadingComPagar && <p>No hay datos</p>}
                                                        {brokersComPagar.map((item, idx) => {
                                                            return (
                                                                <TablesRecordsItemsBrokersCommissionsPay
                                                                    key={idx}
                                                                    data={item}
                                                                    handleAuthorizationMov={handleAuthorizationMov}
                                                                    handleDisawovalMov={handleDisawovalMov}
                                                                    loadingAuthorization={loadingAuthorization}
                                                                    loadingDisavowal={loadingDisavowal}
                                                                />
                                                            );
                                                        })}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommissionsBrokersDetails;
