const getEjecutivoBonos=async(IDEjecutivo, Ffin, tipoPersona)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url=`${host}api/comisiones/bonoscomisiones`

    const token=localStorage.getItem('token')
    const data = {
        "idejecutivo": IDEjecutivo,
        "tipopersonal": tipoPersona,
        "fechasaldo": Ffin
    }
    const resp = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        },
        body: JSON.stringify(data)
    });
    const { result, Error } = await resp.json();
        let idx = 0;
        let ejecutivoBonos = [];
        if(result !== undefined){
            ejecutivoBonos = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        idmov: elemen.ID,
                        idejecutivo:IDEjecutivo,
                        idpuesto: elemen.IDPuesto,
                        importebono: elemen.ImporteBono,
                        porcentajebono: elemen.PorcentajeBono,
                        producto: elemen.Producto,
                        descripcion: elemen.Descripcion,
                        nbono: elemen.nBono,
                        tipopersonal: elemen.TipoPersonal,
                        lactivo: elemen.lActivo,
                        observaciones: elemen.Observaciones,
                        periodo: elemen.Periodo,
                        yy: elemen.YY
                    }
                });
        } else {
            ejecutivoBonos = [{idx:idx+=1 , message: Error}];
        }
        return ejecutivoBonos;
}

export default getEjecutivoBonos;
