import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from '../views/Home';
import CommissionsExecutives from '../views/CommissionsExecutives';
import CommissionsExecutivesDetails from '../views/CommissionsExecutivesDetails';
import CommissionsExecutivesProcess from '../views/CommissionsExecutivesProcess';
import CommissionsSaldosDetails from '../views/CommissionsSaldosDetails';
import CommissionsExtensionists from '../views/CommissionsExtensionists';
import CommissionsExtensionistsDetails from '../views/CommissionsExtensionistsDetails';
import CommissionsExtensionistsContractsAssigned from '../views/CommissionsExtensionistsContractsAssigned';
import CommissionsExtensionistsProcess from '../views/CommissionsExtensionistsProcess';
import CommissionsSaldosDetailsExtensionists from '../views/CommissionsSaldosDetailsExtensionists';
import CommissionsInvNvasDetails from '../views/CommissionsExecutivesProductNewDetails';
import CommissionsBrokers from '../views/CommissionsBrokers';
import CommissionsBrokersDetails from '../views/CommissionsBrokersDetails';
import CommissionsBrokersContractsAssigned from '../views/CommissionsBrokersContractsAssigned';
import CommissionsBrokersProcess from '../views/CommissionsBrokersProcess';
import Companies from '../views/Companies';
import Setting from '../views/Settings';
import Login from '../views/login';
import Logoff from '../components/logoff';
import PrivateRoute from './PrivateRoutes';
import Profile from '../views/Profile';
// import Contact from '../views/Contact';
import Commissions from '../views/Commissions';
import Support from '../views/Support';
import Errorpage from '../views/Error';

const Rutas=()=>{
    return (
        <Router>
            <Switch>
                <PrivateRoute path="/home" component={Home}/>
                <PrivateRoute path="/companies" component={Companies}/>

                <PrivateRoute path="/commissions-executives" component={CommissionsExecutives}/>
                <PrivateRoute path="/commissions-executives-details" component={CommissionsExecutivesDetails}/>
                <PrivateRoute path="/commissions-executives-process" component={CommissionsExecutivesProcess}/>

                <PrivateRoute path="/commissions-extensionists" component={CommissionsExtensionists}/>
                <PrivateRoute path="/commissions-extensionists-details" component={CommissionsExtensionistsDetails}/>
                <PrivateRoute path="/commissions-extensionists-contracts-assigned" component={CommissionsExtensionistsContractsAssigned}/>
                <PrivateRoute path="/commissions-extensionists-process" component={CommissionsExtensionistsProcess}/>

                <PrivateRoute path="/commissions-brokers" component={CommissionsBrokers}/>
                <PrivateRoute path="/commissions-brokers-details" component={CommissionsBrokersDetails}/>
                <PrivateRoute path="/commissions-brokers-contracts-assigned" component={CommissionsBrokersContractsAssigned}/>
                <PrivateRoute path="/commissions-brokers-process" component={CommissionsBrokersProcess}/>

                <PrivateRoute path="/commissions-saldos-details/:TIPO/:EJECUTIVO/:PRODUCTO/:DATE/:TIPOEJECUTIVO" component={CommissionsSaldosDetails}/>
                <PrivateRoute path="/commissions-saldos-extensionists-details/:TIPO/:EJECUTIVO/:PRODUCTO/:DATE/:TIPOEJECUTIVO" component={CommissionsSaldosDetailsExtensionists}/>
                <PrivateRoute path="/commissions-inversiones-nuevas-details/:IDMOV/:PRODUCTO" component={CommissionsInvNvasDetails}/>
                <PrivateRoute path="/profile" component={Profile}/>
                <PrivateRoute path="/settings" component={Setting}/>
                <PrivateRoute path="/commissions" component={Commissions}/>
                <PrivateRoute path="/support" component={Support}/>
                <PrivateRoute path="/settings" component={Setting}/>
                <PrivateRoute exact path="/" component={Home}/>
                <Logoff exact path="/logoff" component={Logoff}/>
                <Route exact path="/login" component={Login}/>
                <Route component={Errorpage}/>
            </Switch>
        </Router>
    )
}

export default Rutas;