const printReportExecutive = async (data) => {
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let url = '';
    if (data.tipoPersona === 'EXT') {
        url = `${host}api/comisiones/obtenerpdfextensionistapago`;
    } else if (data.tipoPersona === 'EJE') {
        url = `${host}api/comisiones/obtenerpdfejecutivopago`;
    } else if (data.tipoPersona === 'BKS') {
        url = `${host}api/comisiones/obtenerpdfbrokerpago`;
    }
    const token = localStorage.getItem('token');
    const resp = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        },
        body: JSON.stringify(data.info)
    });
    const { Success, base64, filename, mimetype, encoding, Message, Error } = await resp.json();
    let idx = 0;
    let printPay;
    if (Success) {
        printPay = { Success, base64, filename, mimetype, encoding, Message };
    } else {
        printPay = { idx: (idx += 1), Success, Message: Error };
    }
    return printPay;
};

export default printReportExecutive;
