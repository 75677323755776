const getEjecutivoColocacionNuevas=async(IDEjecutivo, Ffin, sModulo)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let urlProducto = "";
    if ( sModulo === "CR" ){
        urlProducto = "creditosnuevos";
    } else if ( sModulo === "AP" ){
        urlProducto = "apnuevos";
    } else if ( sModulo === "VP" ){
        urlProducto = "vpnuevos";
    }
    const url=`${host}api/comisiones/${ urlProducto }`;

    const token=localStorage.getItem('token')
    const data = {
        "idejecutivo": IDEjecutivo,
        "producto": sModulo,
        "tipopersonal": 'EJE',
        "fechaProc": Ffin
    }
    const resp = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        },
        body: JSON.stringify(data)
    });
        const { result, Success, Error } = await resp.json();
        let idx = 0;
        let ejecutivoColNuevas = [];
        if(result !== undefined){
            ejecutivoColNuevas = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        idmov: elemen.ID,
                        idEjecutivo:IDEjecutivo,
                        cliente: elemen.Cliente,
                        empresa: elemen.sEmpresa,
                        comisioncli: elemen.PorcComiCli,
                        porcentajecomision: elemen.PorcentajeComision,
                        comision: elemen.Comision,
                        producto: elemen.Producto,
                        Success
                    }
                });
        } else {
            ejecutivoColNuevas = [{idx:idx+=1, Success, message: Error}];
        }
        return ejecutivoColNuevas;
}

export default getEjecutivoColocacionNuevas;
