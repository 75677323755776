import React from 'react';
import { NavLink } from "react-router-dom"

const Logo=()=>{
    return(
        <div className="navbar-brand">
            <NavLink to="/" className="navbar-item">
              <img
                src="https://intranet.credicormexicano.com.mx/img/logos/Imagotipo.png"
                alt="brand"
              />
            </NavLink>
          </div>
    )

}

export default Logo