import  { useState, useEffect } from 'react'
import getEjecutivos from '../helpers/getEjecutivos'



const useGetEjecutivos = () => {
    const [state, setState] = useState({
        data: [],
        loading: true
    })

    useEffect( ()=>{
        getEjecutivos()
            .then( ejecutivos => {
                setState({
                    data: ejecutivos,
                    loading: false
                })
            } )
      },[])

    return state

}

export default useGetEjecutivos