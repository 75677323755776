import React, { useState, useContext } from 'react';
import Header from '../layout/header';
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItems from '../components/TablesRecordsItemsCommissionExecutiveProcess';
import Asidebar from '../layout/asidebar';
import { UserContext } from '../helpers/auth';
import swal from 'sweetalert';
import useGetBrokersCommissionProcess from '../hooks/useGetCommissionsExecutivesProcess';
import processCommisionBrokers from '../helpers/processCommissionBrokers';
const menu = [
    { title: 'Brokers', link: '/commissions-brokers', icon: 'fas fa-briefcase icon' },
    { title: 'Detalles', link: '/commissions-brokers-details', icon: 'fas fa-info-circle icon' },
    { title: 'Asig. Operaciones', link: '/commissions-brokers-contracts-assigned', icon: 'fas fa-folder-plus icon' },
    { title: 'Calculo de Comisiones', link: '/commissions-brokers-process', icon: 'fas fa-cog icon' }
];

const data = [
    {
        Idx: 0,
        title: '#'
    },
    {
        Idx: 1,
        title: 'Fecha de Ejecución'
    },
    {
        Idx: 2,
        title: 'Periodo'
    },
    {
        Idx: 3,
        title: 'YY'
    }
];

const CommissionsBrokersProcess = () => {
    const { userAuth } = useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    // const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lOperacionesLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? true : false;

    const [loadingCommissionProcess, setLoadingCommissionProcess] = useState(false);
    const [FfinInput, setFfinInput] = useState('');
    const [Ffin, setFfin] = useState('');

    const handleCommissionProcess = (fecha, lOperacionesLogin) => {
        if (lOperacionesLogin) {
            setLoadingCommissionProcess(true);
            processCommisionBrokers({ fechaProc: fecha }).then((processCommission) => {
                if (processCommission.Success) {
                    setTimeout(() => {
                        const partsDate = Ffin.split('-');
                        setFfin(`${partsDate[0]}-${partsDate[1]}-15`);
                        setLoadingCommissionProcess(false);
                        swal('Terminado', processCommission.Message);
                    }, 5000);
                } else if (!processCommission.Success && processCommission.Success !== undefined) {
                    setLoadingCommissionProcess(false);
                    swal('Error', processCommission.Message);
                }
            });
        } else {
            swal('Error', 'No tiene permisos para ejecutar el proceso');
        }
    };

    const { data: dataProcess, loadingData } = useGetBrokersCommissionProcess(Ffin, 'BKS');

    return (
        <>
            <Header />
            <div className="columns is-variable is-0">
                <Asidebar menu={menu} />
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">Comisiones Ejecutivos</h1>
                            </div>
                        </div>
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <div className="column is-full">
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <div className="field-body">
                                                        <div className="field-label">
                                                            <label className="label has-text-info-light">Fecha</label>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <input
                                                                    className="input"
                                                                    type="date"
                                                                    value={FfinInput}
                                                                    onChange={(e) => {
                                                                        setFfinInput(e.target.value);
                                                                        setFfin(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                {loadingCommissionProcess ? (
                                                                    <button className="button is-info is-loading">
                                                                        Procesar
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="button is-info"
                                                                        onClick={(e) =>
                                                                            handleCommissionProcess(
                                                                                Ffin,
                                                                                lOperacionesLogin
                                                                            )
                                                                        }
                                                                    >
                                                                        Procesar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            {/* componente dinamicos tablas */}
                                            <div className="media">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {data.map((item, id) => {
                                                                return <TableTitlesItems key={id} data={item} />;
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    {loadingData && <p>No hay datos</p>}
                                                    {dataProcess.map((item, idx) => {
                                                        return <TablesRecordsItems key={idx} data={item} />;
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommissionsBrokersProcess;
