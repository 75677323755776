import { useState, useEffect } from 'react';
import getEjecutivosColocacionNvas from '../helpers/getEjecutivoColocacionNvas'

const useGetExecutiveColocacionNuevas = (IDEjecutivo, Ffin, sModulo) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getEjecutivosColocacionNvas(IDEjecutivo, Ffin, sModulo)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[IDEjecutivo, Ffin, sModulo])

    return state

}

export default useGetExecutiveColocacionNuevas;