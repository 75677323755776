import { useState, useEffect } from 'react';
import getBrokersCommissionPay from '../helpers/getBrokersCommissionPay';

const useGetBrokersCommissionPay = (statusInput, loadingAuthorization, loadingDisavowal, loadingProcessPay) => {
    const [state, setState] = useState({
        data: [],
        loadingData: true
    });

    useEffect(() => {
        getBrokersCommissionPay(statusInput).then((details) => {
            setState({
                data: details,
                loadingData: false
            });
        });
    }, [statusInput, loadingAuthorization, loadingDisavowal, loadingProcessPay]);

    return state;
};

export default useGetBrokersCommissionPay;
