import { useState, useEffect } from 'react';
import getCommissionExecutivesProcess from '../helpers/getCommissionsExecutivesProcess';

const useGetCommissionExecutivesProcess = (FechaProc, tipoPersona) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getCommissionExecutivesProcess(tipoPersona)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[FechaProc,tipoPersona])

    return state

}

export default useGetCommissionExecutivesProcess