import React from "react";
import { Link } from 'react-router-dom'
import { financial } from '../functions/Generales'

const TablesRecordsItems = (props) => {
  const { idx,Success, product, saldoIni, saldoFin, dif, meta, saldoVen, pCarteraV, comision, sProducto, idEjecutivo, ffinal, tipoPersona} = props.data;
  if ( Success ) {
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>{product}</td>
          {tipoPersona === "EJE" ? <td align='right'><Link to={`/commissions-saldos-details/INICIAL/${idEjecutivo}/${sProducto}/${ffinal}/EJE`}>{financial(saldoIni)}</Link></td> : <td align='right'><Link to={`/commissions-saldos-extensionists-details/INICIAL/${idEjecutivo}/${sProducto}/${ffinal}/EXT`}>{financial(saldoIni)}</Link></td>}
          {tipoPersona === "EJE" ? <td align='right'><Link to={`/commissions-saldos-details/FINAL/${idEjecutivo}/${sProducto}/${ffinal}/EJE`}>{financial(saldoFin)}</Link></td> : <td align='right'><Link to={`/commissions-saldos-extensionists-details/FINAL/${idEjecutivo}/${sProducto}/${ffinal}/EXT`}>{financial(saldoFin)}</Link></td>}
          <td align='right'>{financial(dif)}</td>
          { tipoPersona === "EJE" ? <td align='right'>{financial(meta)}</td> : ""} 
          { tipoPersona === "EJE" ? <td align='right'>{financial(saldoVen)}</td> : ""} 
          { tipoPersona === "EJE" ? <td align='right'>{pCarteraV}</td> : ""} 
          { tipoPersona === "EJE" ? <td align='right'>{financial(comision)}</td> : ""} 
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }
};

export default TablesRecordsItems;
