import { useState, useEffect } from 'react';
import getSaldosDetails from '../helpers/getSaldosDetails'
const useGetSaldosDetails = (TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO) => {

    const [state, setState] = useState({
        data: [],
        loading: true
    })

    useEffect( ()=>{
        getSaldosDetails(TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO)
            .then( saldos => {
                setState({
                    data: saldos,
                    loading: false
                })
            } )
      },[TIPO, PRODUCTO, EJECUTIVO, DATE, TIPOEJECUTIVO])

    return state

}

export default useGetSaldosDetails