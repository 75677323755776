function financial(value) {
    return (
        '$' +
        Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)
    ); //Number.parseFloat(value).toFixed(2)
}

function formatNum(value) {
    return Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value); //Number.parseFloat(value).toFixed(2)
}

function downloadFiles(data) {
    const linkSourse = `data:${data.mimetype};${data.encoding},${data.base64}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSourse;
    downloadLink.download = data.filename;
    downloadLink.click();
}

function filterEjectivosConsulta(data, finicio, ftermino, arrPuestos, lEjecutivos) {
    const ejecutivosFilter =
        lEjecutivos === 'S'
            ? data.filter(
                  (item) =>
                      (item.status === 'S' ||
                          (item.status === 'N' &&
                              ((item.FBaja >= finicio && item.FBaja <= ftermino) || item.FBaja >= ftermino))) &&
                      item.IDDepartamento === 1 &&
                      arrPuestos.includes(item.IDPuesto) &&
                      item.FAlta <= ftermino
              )
            : data.filter(
                  (item) =>
                      (item.status === 'S' ||
                          (item.status === 'N' &&
                              ((item.FBaja >= finicio && item.FBaja <= ftermino) || item.FBaja >= ftermino))) &&
                      arrPuestos.includes(item.IDPuesto) &&
                      item.FAlta <= ftermino
              );

    const ejecutivos = ejecutivosFilter.map((item) => {
        return {
            id: item.id,
            ejecutivo: item.Nombre + ' ' + item.Apellido1 + ' ' + item.Apellido2
        };
    });
    return ejecutivos;
}

export { formatNum, financial, downloadFiles, filterEjectivosConsulta };
