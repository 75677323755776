import React from "react";
import { financial } from '../functions/Generales'

const TablesRecordsItemsExecutivesBonos = (props) => {
const { idx, importebono, descripcion, message} = props.data;
  if ( message === undefined ){
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>{descripcion}</td>
          <td align='right'>{financial(importebono)}</td>
        </tr>
      </tbody>
    );
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsExecutivesBonos;
