import React, { useContext } from "react";
import { financial } from '../functions/Generales';
import { UserContext } from "../helpers/auth";
import { formatoFecha } from '../functions/Dates';

const TablesRecordsItemsCommission = (props) => {
  const {userAuth}=useContext(UserContext);

  const IDDepartamentoUserLogin = userAuth.idDepartamento;
  const IDPersonalUserLogin = userAuth.idPersonal;
  const lSolicitarPago = IDDepartamentoUserLogin === 6 ? true : false
  const lMesaControlLogin =  IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 ? true: false

  const { idx, fecha, mes, yy, total_apagar, status , Ejecutivo, id_ejecutivo, id_comision} = props.data;
  const lSolicitarEjecutivoLogueado = parseFloat(IDPersonalUserLogin) === parseFloat(id_ejecutivo) ? true : false;
  if ( props.Success ) {
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td>{formatoFecha(fecha)}</td>
          <td>{Ejecutivo}</td>
          <td align="center">{mes}</td>
          <td align="center">{yy}</td>
          <td align="right">{financial(total_apagar)}</td>
          <td>{ status === 1 ? 'Aprobada' : status === 2 ? 'Confirmada' : status === 3 ? 'Pago Solicitado' : '' }</td>
          <td>
            <div>
              {
                lMesaControlLogin && status === 1 ? <button className="button is-info fas fa-check" onClick={ (e) => props.handleAuthCommission( id_comision, 2 ) }></button> :  status === 3 ? <button className="button is-info fas fa-clipboard-check"></button> : ''
              }
              {
                (lSolicitarPago && status === 2) || (lSolicitarEjecutivoLogueado && status === 2) ? <button className="button is-info fas fa-money-check-alt" onClick={ (e) => props.handleSendPayEje( id_comision, 3, id_ejecutivo, total_apagar,"EJE" ) } ></button> : ""
              }
            </div>
          </td>
        </tr>
      </tbody>
    );
  } else {
    return (
        <tbody>
          <tr>
            <td align='left'>No existe información</td>
          </tr>
        </tbody>
      );
}
};

export default TablesRecordsItemsCommission;
