import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Logo from '../components/Logo'
import { UserContext } from "../helpers/auth";
import NavProfile from '../components/NavProfile';


const Header=()=>{
  
    const {userAuth}=useContext(UserContext);
    const name=userAuth.name
    const data=[ 
        {
          name,
         
        },
    ]
    return (
        <nav className="navbar is-fixed-top box-shadow-y">
          
          <div className="navbar-brand">
            <Link
              to="/"
              role="button"
              className="navbar-burger toggler"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </Link>
            <Logo/>
            {/* <Link to="/" className="navbar-item has-text-weight-bold has-text-black">
              Credicor @Intranet
            </Link> */}
            <span
              role="button"
              className="navbar-burger nav-toggler"
              data-target="navMenu"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div id="navMenu" className="navbar-menu has-background-white">
            <div className="navbar-start">
              <Link to="/" className="navbar-item"><i className="fas fa-home icon"></i>Inicio</Link>
              <Link to="support" className="navbar-item"><i className="far fa-life-ring icon"></i> Soporte</Link>
              <Link to="commissions" className="navbar-item"><i className="fas fa-award icon"></i>Comisiones</Link>
            </div>
            <div className="navbar-end">
              <Link to="notifications" className="navbar-item"><i className="fas fa-bell icon"></i></Link>
              <NavProfile data={data[0]}/>
            </div>
          </div>
        </nav>
    )
}

export default Header