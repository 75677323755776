import React, { useContext } from 'react';
// import { Link } from 'react-router-dom'
// import { financial, formatNum } from '../functions/Generales'
import { financial } from '../functions/Generales';
import { UserContext } from '../helpers/auth';

const TablesRecordsItemsBrokersCommissionsPay = (props) => {
    const { userAuth } = useContext(UserContext);

    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const lAprobarMovs = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 11 ? false : true;

    const {
        idx,
        Success,
        idpay,
        statusPago,
        Personal,
        puestoPersonal,
        Cliente,
        Folio,
        porcComisionPago,
        comPagadaPersonal,
        periodo,
        yy
    } = props.data;
    if (Success) {
        return (
            <tbody>
                <tr>
                    <td>{idx}</td>
                    <td>{Personal}</td>
                    <td>{puestoPersonal}</td>
                    <td>{Cliente}</td>
                    <td align="center">{Folio}</td>
                    <td>{porcComisionPago * 100}</td>
                    <td align="right">{financial(comPagadaPersonal)}</td>
                    <td align="center">{periodo}</td>
                    <td align="center">{yy}</td>
                    {/* {lTitulo==='S' ? <td align='left'><b>{ puesto }</b></td> : <td align='left'>{ puesto }</td>}
          <td align='right'>{idPersonal==='' ? '' : formatNum(porcP)}</td>
          <td align='right'>{idPersonal==='' ? '' : formatNum(porcC)}</td>
          {lTitulo==='S' ? <td align='right'><b>{financial(comAP)}</b></td> : <td align='right'>{financial(comAP)}</td>}
          {lTitulo==='S' ? <td align='right'><b>{financial(comCR)}</b></td> : <td align='right'>{financial(comCR)}</td>}
          {lTitulo==='S' ? <td align='right'><b>{financial(comVP)}</b></td> : <td align='right'>{financial(comVP)}</td>}
          <td align='right'>{idpay > 0 ? financial(comIN) : ''}</td>
          <td align='right'>{idPersonal==='' ? '' : financial(bonos)}</td>
          <td align='right'>{idPersonal==='' ? '' : financial(totalPago)}</td> */}
                    <td>
                        <div>
                            {lAprobarMovs ? (
                                ''
                            ) : props.loadingAuthorization.lLoadingData &&
                              props.loadingAuthorization.IDMov === idpay ? (
                                <button className="button is-info is-loading"></button>
                            ) : (props.loadingAuthorization.lLoadingData || props.loadingDisavowal.lLoadingData) &&
                              statusPago === 1 ? (
                                <button className="button is-info fas fa-check"></button>
                            ) : statusPago === 1 ? (
                                <button
                                    className="button is-info fas fa-check"
                                    onClick={(e) => {
                                        props.handleAuthorizationMov(idpay);
                                    }}
                                ></button>
                            ) : idpay > 0 && statusPago === 3 ? (
                                <button className="button is-info fas fa-clipboard-check"></button>
                            ) : (
                                ''
                            )}
                            {lAprobarMovs ? (
                                ''
                            ) : props.loadingDisavowal.lLoadingData && props.loadingDisavowal.IDMov === idpay ? (
                                <button className="button is-info is-loading"></button>
                            ) : (props.loadingAuthorization.lLoadingData || props.loadingDisavowal.lLoadingData) &&
                              statusPago === 2 ? (
                                <button className="button is-info fas fa-trash-alt"></button>
                            ) : statusPago === 2 && idpay > 0 ? (
                                <button
                                    className="button is-info fas fa-trash-alt"
                                    onClick={(e) => props.handleDisawovalMov(idpay)}
                                ></button>
                            ) : (
                                ''
                            )}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    } else {
        return (
            <tbody>
                <tr>
                    <td>{idx}</td>
                    <td align="left">No existe información</td>
                </tr>
            </tbody>
        );
    }
};

export default TablesRecordsItemsBrokersCommissionsPay;
