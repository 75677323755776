import React from 'react';
import { financial, formatNum } from '../functions/Generales';

const TablesRecordsItemsContractsPeriod = (props) => {
    const idPersonalAsignado = props.idasignarpersonal;
    const idBrokerAsignado = props.idasignarbroker;

    const { idx, Success, idMov, cliente, folio, sobretasa, plazo, autorizado, fInicio, tipooperacion, asignado } =
        props.data;
    if (Success) {
        return (
            <tbody>
                <tr>
                    <td>{idx}</td>
                    <td align="left">{cliente}</td>
                    <td align="center">{folio}</td>
                    <td align="center">{plazo}</td>
                    <td align="center">{`${formatNum(sobretasa)}%`}</td>
                    <td align="right">{financial(autorizado)}</td>
                    <td align="center">{fInicio}</td>
                    <td align="center">{tipooperacion}</td>
                    <td align="left">{asignado}</td>
                    <td>
                        <div>
                            {props.loadingAssigned.lLoadingData && props.loadingAssigned.IDMov === idMov ? (
                                <button className="button is-info is-loading"></button>
                            ) : (props.loadingAssigned.lLoadingData || props.loadingDeallocate.lLoadingData) &&
                              asignado === '' ? (
                                <button className="button is-info fas fa-check"></button>
                            ) : asignado === '' ? (
                                <button
                                    className="button is-info fas fa-check"
                                    onClick={(e) => {
                                        props.handleAssignedMov(idMov, idBrokerAsignado, idPersonalAsignado);
                                    }}
                                ></button>
                            ) : (
                                ''
                            )}
                            {props.loadingDeallocate.lLoadingData && props.loadingDeallocate.IDMov === idMov ? (
                                <button className="button is-info is-loading"></button>
                            ) : (props.loadingAssigned.lLoadingData || props.loadingDeallocate.lLoadingData) &&
                              asignado !== '' ? (
                                <button className="button is-info fas fa-trash-alt"></button>
                            ) : asignado !== '' && idMov > 0 ? (
                                <button
                                    className="button is-info fas fa-trash-alt"
                                    onClick={(e) => props.handleDeallocateMov(idMov)}
                                ></button>
                            ) : (
                                ''
                            )}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    } else {
        return (
            <tbody>
                <tr>
                    <td>{idx}</td>
                    <td align="left">No existe información</td>
                </tr>
            </tbody>
        );
    }
};

export default TablesRecordsItemsContractsPeriod;
