import React from 'react'
import { useParams } from "react-router-dom";
import Header from '../layout/header'
import TableTitlesItems from '../components/TablesTitlesItems';
import TablesRecordsItems from '../components/TablesRecordsItemsEjecutivoProductsNewsDetails';
import Asidebar from '../layout/asidebar';
import useGetExecutivesProductNewDetails from '../hooks/useGetEjecutivoProductNewDetails';
const menu = [
    {   title:'Ejecutivos',
        link:'/commissions-executives',
        icon:'fas fa-users icon'
    },
    {   title:'Detalles',
        link:'/commissions-executives-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-executives-process',
        icon:'fas fa-cog icon'
    }
]

const data=[
    {
        Idx:0,
        title:'#', 
    },
    {
        Idx:1,
        title:'Folio', 
    },
    {
        Idx:2,
        title:'Cliente', 
    },
    {
        Idx:3,
        title:'Tipo Cliente', 
    },
    {
        Idx:4,
        title:'Ejecutivo.', 
    },
    {
        Idx:5,
        title:'Producto', 
    },
    {
        Idx:6,
        title:'Importe', 
    },
    {
        Idx:7,
        title:'Plazo', 
    },
    {
        Idx:8,
        title:'Tipo Instrucción', 
    }
]

const dataColocacion=[
    {
        Idx:0,
        title:'#', 
    },
    {
        Idx:1,
        title:'Folio', 
    },
    {
        Idx:2,
        title:'Cliente', 
    },
    {
        Idx:3,
        title:'Tipo Cliente', 
    },
    {
        Idx:4,
        title:'Ejecutivo.', 
    },
    {
        Idx:5,
        title:'Producto', 
    },
    {
        Idx:6,
        title:'Importe', 
    },
    {
        Idx:7,
        title:'Tipo Instrucción', 
    }
]

const CommissionsExecutivesDetails=()=>{
    const { IDMOV, PRODUCTO } = useParams();
    const {data: ejecutivosDetail, loading } = useGetExecutivesProductNewDetails(IDMOV);
    let dataColumnas;
    if ( PRODUCTO === "IN" ){
        dataColumnas = data;
    } else {
        dataColumnas = dataColocacion;
    }
    return(
        <>
            <Header/>         
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                    Inversiones Nuevas Detalles
                                </h1>
                            </div>
                        </div>
                        <div className="columns  is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">                
                                    <div className="card">
                                        <header className="card-header">
                                                <div className="card-header-title has-background-danger has-text-light">
                                                </div>
                                        </header>
                                        <div className="card-content">
                                        {/* componente dinamicos tablas */}
                                            <div className="media">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                    {dataColumnas.map((item, id)=>{
                                                        return <TableTitlesItems key={id}data={item}/>
                                                    })}
                                                    </tr>
                                                </thead>
                                                    {loading && <p>Cargando...</p> }
                                                    {ejecutivosDetail.map((item, idx)=>{
                                                    return <TablesRecordsItems key={idx}data={item}/>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    {/* TODO:Content */}
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div> 
            </div>
        </>
    )
}

export default CommissionsExecutivesDetails;