import React from "react";
import { financial } from '../functions/Generales'

const TablesRecordsItems = (props) => {
  const { idx, cliente, tipocliente, ejecutivo, folio, producto, importe , saldovig, saldoVen, periodo, yy} = props.data;
  return (
    <tbody>
      <tr>
        <td>{idx}</td>  
        <td>{ejecutivo}</td>
        <td>{cliente}</td>
        <td>{tipocliente}</td>
        <td>{folio}</td>
        <td>{producto}</td>
        <td>{financial(importe)}</td>
        <td>{financial(saldovig)}</td>
        <td>{financial(saldoVen)}</td>
        <td>{periodo}</td>
        <td>{yy}</td>
      </tr>
    </tbody>
  );
};

export default TablesRecordsItems;
