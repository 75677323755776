const getCommission=async(data)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url=`${host}api/comisiones/listadocomisionejecutivo`
    
    const token=localStorage.getItem('token')
    if (!data.id_ejecutivo) {
        delete data.id_ejecutivo
    }
    if (!data.status) {
        delete data.status
    }
    const resp = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token ,
            "Access-Control-Allow": "*"
        },
        body: JSON.stringify(data)
    });
    const { result, Error,  Success} = await resp.json();
    let idx = 0;
    let commission = [];
    let success
    if(result !== undefined){
        success = Success
        commission = result.map(elemen => {
            return {
                idx: idx+=1,
                id_comision: elemen.id_comision,
                fecha: elemen.fecha,
                mes: elemen.mes,
                id_ejecutivo: elemen.id_ejecutivo,
                total_comi_inv: elemen.total_comi_inv,
                total_comi_apvp: elemen.total_comi_apvp,
                total_comi_vp: elemen.total_comi_vp,
                total_comi_cred: elemen.total_comi_cred,
                total_apagar: elemen.total_apagar,
                status: elemen.status,
                total_bono: elemen.total_bono,
                yy: elemen.yy,
                tipo: elemen.tipo,
                inist: elemen.inist,
                finst: elemen.finst,
                PagoDiffSaldosAP: elemen.PagoDiffSaldosAP,
                PagoDiffSaldosCR: elemen.PagoDiffSaldosCR,
                PagoDiffSaldosIN: elemen.PagoDiffSaldosIN,
                PagoDiffSaldosPR: elemen.PagoDiffSaldosPR,
                PagoDiffSaldosVP: elemen.PagoDiffSaldosVP,
                Ejecutivo: elemen.Ejecutivo
            }
        });
    } else {
        commission = [{idx:idx+=1 , message: Error}];
        success = false
    }
    return { commission, success };

}

export default getCommission
