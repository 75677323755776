const getCommissionExecutivesProcess=async(tipoPersona)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url = `${host}api/comisiones/periodosprocesados/${ tipoPersona }`;

  const token=localStorage.getItem('token')
  const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
        const { result, Success, Message, Error } = await resp.json();

        let idx = 0
        let commissionProcess = []
        if(result !== undefined){
            commissionProcess = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        fecha: elemen.Fecha,
                        periodo: elemen.Periodo,
                        yy: elemen.YY,
                        Success,
                        Message
                    }
                })
        } else {
            commissionProcess = [{idx:idx+=1, Success, Message, product: Error}]
        }
        return commissionProcess
}
export default getCommissionExecutivesProcess