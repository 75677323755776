const getEjecutivoInversionesNuevas=async(IDEjecutivo, Ffin)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`
    const host = process.env.REACT_APP_URL_API_INTRANET;
    const url=`${host}api/comisiones/inversionesnuevas`

    const token=localStorage.getItem('token')
    const data = {
        "idejecutivo": IDEjecutivo,
        "producto": 'IN',
        "tipopersonal": 'EJE',
        "fechaProc": Ffin
    }
    const resp = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        },
        body: JSON.stringify(data)
    });
        const { result, Error, Success } = await resp.json();
        let idx = 0;
        let ejecutivoInvNuevas = [];
        if(result !== undefined){
            ejecutivoInvNuevas = result.map(elemen => {
                    return {
                        idx: idx+=1,
                        idmov: elemen.ID,
                        idEjecutivo:IDEjecutivo,
                        importe: elemen.Importe,
                        plazo: elemen.Plazo,
                        cliente: elemen.Cliente,
                        empresa: elemen.sEmpresa,
                        porcentajecomision: elemen.PorcentajeComision,
                        comision: elemen.Comision,
                        producto: elemen.Producto,
                        Success
                    }
                });
        } else {
            ejecutivoInvNuevas = [{idx:idx+=1, Success, message: Error}];
        }
        return ejecutivoInvNuevas;
}

export default getEjecutivoInversionesNuevas;
