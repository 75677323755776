import React, { useState, useContext } from 'react'
import Header from '../layout/header';
import TableTitlesItems from '../components/TablesTitlesItems';
import Asidebar from '../layout/asidebar'
import TablesRecordsItems from '../components/TablesRecordsItemsCommission';
import useGetCommission from "../hooks/useGetCommission";
import useGetEjecutivos from "../hooks/useGetEjectutivos";
import { UserContext } from "../helpers/auth";
import { dates } from '../functions/Dates';
import { filterEjectivosConsulta } from '../functions/Generales'
import swal from 'sweetalert';
import sendPayCommission from '../helpers/sendPayCommission';
import authCommissionEjecutivo from '../helpers/authCommissionEjecutivo';
const menu = [
    {   title:'Ejecutivos',
        link:'/commissions-executives',
        icon:'fas fa-users icon'
    },
    {   title:'Detalles',
        link:'/commissions-executives-details',
        icon:'fas fa-info-circle icon'
    },
    {   title:'Calculo de Comisiones',
        link:'/commissions-executives-process',
        icon:'fas fa-cog icon'
    }
]

    const data=[
        {
            Idx:0,
            title:'#',
        },        
        {
            Idx:1,
            title:'Fecha',
        },
        {
            Idx:2,
            title:'Ejecutivo',
        },
        {
            Idx:3,
            title:'Periodo',
        },
        {
            Idx:4,
            title:'Año',
        },
        {
            Idx:5,
            title:'Monto a pagar',
        },
        {
            Idx:6,
            title:'Estatus',
        },
        {
            Idx:7,
            title:'Acciones',
        }

    ]

const CommissionsExecutives=()=>{
    const {userAuth}=useContext(UserContext);
    const IDDepartamentoUserLogin = userAuth.idDepartamento;
    const IDPersonalUserLogin = userAuth.idPersonal;
    // const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 || IDPersonalUserLogin === 18 ? false : true
    const lEjecutivoLogin = IDDepartamentoUserLogin === 6 || IDDepartamentoUserLogin === 9 || IDDepartamentoUserLogin === 11 ? false : true
    const lMesaControlLogin =  IDDepartamentoUserLogin === 9 ? true: false

    let nStatusValIni;
    if ( lEjecutivoLogin ) {
        nStatusValIni = 2;
    } else if ( lMesaControlLogin ) {
        nStatusValIni = 1;
    } else { nStatusValIni = 1 }

    const [FfinInput, setFfinInput] = useState("");
    const [IDEjecutivoInput, setIDEjecutivoInput] = useState("");
    const [statusInput, setstatusInput] = useState( nStatusValIni );
    const [Ffin, setFfin] = useState("");
    const [FEjecutivos, setFEjecutivos] = useState("");
    const [IDEjecutivo, setIDEjecutivo] = useState("");
    const [loadingSendPayEje, setLoadingSendPayEje] = useState({lLoadingData: false,IDComision:0});
    const [loadingAuthCommission, setLoadingAuthCommission] = useState({lLoadingData: false,IDComision:0});

    const fechaEjecutivo = dates(FEjecutivos)
    const { data: ejecutivosAll, loading } = useGetEjecutivos();
    const ejecutivos = filterEjectivosConsulta(ejecutivosAll,fechaEjecutivo.startDay,fechaEjecutivo.endDay,[1,2,3,10,13],'S');

    const handleInputValue = () => {
        if ( lEjecutivoLogin ) { setIDEjecutivoInput(IDPersonalUserLogin) }
        setFfin(FfinInput)
        setIDEjecutivo(IDEjecutivoInput)
    }

    const handleAuthCommission = (IDComision, statusm) => {
        setLoadingAuthCommission({lLoadingData: true,IDComision});
        authCommissionEjecutivo({id_comision: IDComision, status: statusm})
        .then( assi => {
            if (assi.Success) {
                setLoadingAuthCommission({lLoadingData: false,IDComision});
                swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
                setLoadingAuthCommission({lLoadingData: false,IDComision});
                assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
    }

    const handleSendPayEje = (IDComision, statusm, IDEjecutivo, importe, tipoPersonam) => {
        setLoadingSendPayEje({lLoadingData: true,IDComision});
        sendPayCommission({id_comision: IDComision, status: statusm, id_ejecutivo:IDEjecutivo, ImporteSolicitado: importe, tipoPersona: tipoPersonam})
        .then( assi => {
            if (assi.Success) {
                setLoadingSendPayEje({lLoadingData: false,IDComision});
                swal("Mensaje", assi.Message);
            } else if( !assi.Success && assi.Success !== undefined ) {
                setLoadingSendPayEje({lLoadingData: false,IDComision});
                assi.Error ? swal("Mensaje", assi.Error) : swal("Mensaje", assi.Message)
            }
        } )
    }

    const tipo = 1
    const { dataCommission } = useGetCommission(IDEjecutivo,Ffin, tipo, statusInput,loadingSendPayEje, loadingAuthCommission)
    const commissions = dataCommission.commission ? dataCommission.commission : []
    
    return(
        <>
            <Header/>
            <div className="columns is-variable is-0">
                <Asidebar menu={menu}/>
                <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
                    <div className="p-1">
                        <div className="columns is-variable is-desktop">
                            <div className="column">
                                <h1 className="title">
                                Comisiones Ejecutivos
                                </h1>
                            </div>
                        </div>
                        <div className="columns  is-variable is-desktop">
                            <div className="column">
                                <div className ="column is-full">
                                    <div className="card">
                                        <header className="card-header">
                                            <div className="card-header-title has-background-danger has-text-light">
                                                <div className="field is-horizontal">
                                                    <div className="field-body">
                                                    <div className="field-label">
                                                            <label className="label has-text-info-light">Fecha</label>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <input
                                                                    className="input"
                                                                    type="date"
                                                                    value={FfinInput}
                                                                    onChange={(e) => {
                                                                        setFfinInput(e.target.value);
                                                                        setFEjecutivos(e.target.value);
                                                                        if ( lEjecutivoLogin ) { setIDEjecutivoInput(IDPersonalUserLogin) }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="field-label">
                                                            <label className="label has-text-info-light">Ejecutivo</label>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <div className="select">
                                                                    <select
                                                                        value={ lEjecutivoLogin ? IDPersonalUserLogin: IDEjecutivoInput }
                                                                        onChange={(e) => {
                                                                            setIDEjecutivoInput(e.target.value);
                                                                        }}
                                                                        disabled = { lEjecutivoLogin }
                                                                    >
                                                                        <option disabled = {true} value=''>seleccione una opcion</option>
                                                                        {ejecutivos.map((item, idx)=>{
                                                                            return <option value={item.id}>{item.ejecutivo}</option>
                                                                        })}
                                                                        
                                                                        <option value={null}>Todos</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                <div className="select">
                                                                    <select
                                                                        value={statusInput}
                                                                        onChange={(e) => {
                                                                            setstatusInput(e.target.value);
                                                                        }}
                                                                    >
                                                                        <option disabled = {true} value=''>seleccione una opcion</option>
                                                                        <option disabled={ lEjecutivoLogin ? true: false } value={ 1 }>Aprobada</option>
                                                                        <option disabled={ lMesaControlLogin ? true: false }value={2}>Confirmada</option>
                                                                        <option disabled={ lMesaControlLogin ? true: false }value={3}>Pago Solicitado</option>
                                                                        <option disabled={ lEjecutivoLogin || lMesaControlLogin ? true: false } value={null}>Todos</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            <div className="control">
                                                                {loading ? <button className="button is-info is-loading" >Buscar</button> : <button className="button is-info" onClick={handleInputValue}  >Buscar</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                        {/* componente dinamicos tablas */}
                                            <div className="media">


                                                <table className="table">
                                                <thead>
                                                    <tr>
                                                    {data.map((item, id)=>{
                                                        return <TableTitlesItems key={id}data={item}/>
                                                    })}
                                                    </tr>
                                                </thead>
                                                    {
                                                        commissions.map((item, idx)=>{
                                                            return <TablesRecordsItems key={idx}data={item}handleSendPayEje={handleSendPayEje}loadingSendPayEje={loadingSendPayEje}Success={dataCommission.success}handleAuthCommission={handleAuthCommission}loadingAuthCommission={loadingAuthCommission}/>
                                                            })
                                                    }
                                                </table>
                                            </div>
                                        </div>

                                    {/* TODO:Content */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default CommissionsExecutives
