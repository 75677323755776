import { useState, useEffect } from 'react';
import getCommission from '../helpers/getCommission'
import { dates } from '../functions/Dates'

const useGetCommission = (IDEjecutivoInput, FfinInput, tipoEjecutivo, statusComision, lLoadingData, lLoadingDataAuth) => {
    const [state, setState] = useState({
        dataCommission: [],
        loadingData: true
    })
    useEffect( ()=>{
        const fechas = dates(FfinInput);
        const dataGetCommission = {
            tipo:tipoEjecutivo,
            status:statusComision !== 'Todos' ? statusComision : NaN,
            id_ejecutivo:parseInt(IDEjecutivoInput ? IDEjecutivoInput : -1),
            periodo: fechas ? parseInt(fechas.month) : null,
            yy: fechas ? parseInt(fechas.yy) : null
        }

        getCommission(dataGetCommission)
            .then( details => {
                setState({
                    dataCommission: details,
                    loadingData: false
                })
            } ).catch( (err) =>{
                setState({
                    dataCommission: err,
                    loadingData: true
                })
            } )
      },[IDEjecutivoInput, FfinInput, statusComision, tipoEjecutivo, lLoadingData, lLoadingDataAuth])

    return state;
}

export default useGetCommission
