import React  from 'react'
import Header from '../layout/header';
import Asidebar from '../layout/asidebar'
const menu = [
    {   title:'Ejecutivos',
        link:'/commissions-executives',
        icon:'fas fa-users icon'
    },
    {   title:'Extensionistas',
        link:'/commissions-extensionists',
        icon:'fas fa-pen-fancy icon'
    },
    {   title:'Brokers',
        link:'/commissions-brokers',
        icon:'fas fa-briefcase icon'
    }
]

const Commissions=()=>{
    return(
        <>
            <Header/>         
            <div className="columns is-variable is-0">
            <Asidebar menu={menu}/>
            <div className="column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile">
            <div className="p-1">
                
                {/* TODO:Content */}
                
            </div>
            </div> 
            </div>
        </>

    )
}


export default Commissions