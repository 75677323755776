import { useState, useEffect } from 'react';
import getExtensionistsCtosAssigned from '../helpers/getExtensionistsCtosAssigned'

const useGetExtensionistsCtosAssigned = (Ffin) => {

    const [state, setState] = useState({
        data: [],
        loadingData: true
    })

    useEffect( ()=>{
        getExtensionistsCtosAssigned(Ffin)
            .then( details => {
                setState({
                    data: details,
                    loadingData: false
                })
            } )
      },[Ffin])

    return state

}

export default useGetExtensionistsCtosAssigned