const getEjecutivos=async(  )=>{
    // const host =`https://auth.sibware.net/`
    // const host =`http://localhost:3500/`
    const host = process.env.REACT_APP_URL_API_AUTH;
    const url=`${host}personal`
  const token=localStorage.getItem('token')
  const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
const { result } = await resp.json();
// const ejecutivos = result.filter(item => (item.status === 'S' || (item.status === 'N' && ((item.FBaja >=IniMes && item.FBaja <= FinMes ) || item.FBaja>= FinMes ) )) && item.Departamento.ID === 1 && item.FAlta<=FinMes)
// const ejecutivosCombo = result.map(item => {
//    return {
//        id: item.id,
//        ejecutivo: item.Nombre + ' ' + item.Apellido1 + ' ' + item.Apellido2
//    }
// })
return result

}

export default getEjecutivos
