import React from "react";
// import { Link } from 'react-router-dom'
import { financial, formatNum } from '../functions/Generales'

const TablesRecordsItemsExtensionistsCtosAssigned = (props) => {
const { idx,Success, cliente, folio, producto, monto, porcComision, comPagada, asignacion} = props.data;
  if ( Success ){
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>{cliente}</td>
          <td align='left'>{folio}</td>
          <td align='left'>{producto}</td>
          <td align='right'>{financial(monto)}</td>
          <td align='right'>{formatNum(porcComision)}</td>
          <td align='right'>{financial(comPagada)}</td>
          <td align='left'>{asignacion}</td>
        </tr>
      </tbody>
    );
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsExtensionistsCtosAssigned;
